import api from "@/service/api";
import router from "@/router";

const state = () => ({
    currentUser: null,
    isRoot: false,
    trainingWheels: true,
})

const mutations = {
    hasAuthToken(state, authToken) {
        state.authToken = authToken;
        api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        localStorage.setItem('authToken', authToken);
    },
    removeAuthToken(state) {
        state.authToken = null;
        api.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem('authToken');
    },
    setCurrentUser(state, value) {
        state.currentUser = value;
    },
    setIsRoot(state, value) {
        state.isRoot = value;
    },
}

const actions = {
    refreshCurrentUser({ commit }) {
        commit('setIsRoot', false)

        api.post('/user/self').then(response => {
            commit('setCurrentUser', response.data.currentUser)

            if (response?.data?.currentUser?.flags?.isRoot) {
                commit('setIsRoot', true)
            }
        })
    },
    login({ commit, dispatch }, authToken) {
        commit('hasAuthToken', authToken)
        dispatch('refreshCurrentUser')
    },
    logout({ commit }) {
        commit('setCurrentUser', null)
        commit('onboarding/removeUser', null, { root: true })
        commit('removeAuthToken')

        router.push('/')
    },
}



export default {
    namespaced: true,
    state,
    actions,
    mutations
}
