<template>
    <div class="py-5 text-center">
        <div class="py-1">
            <b-spinner variant="secondary" />
        </div>
        <div v-if="label">
            {{ label }}
        </div>
        <div v-else>
            {{ $t("Loading...") }}
        </div>
    </div>
</template>

<script>
export default {
    props: ["label"],
};
</script>