<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>Event</th>
                    <th>Screened</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    :key="securityEvent.id"
                    v-for="securityEvent in securityEvents"
                >
                    <td>{{ securityEvent.name }}</td>
                    <td>
                        <font-awesome-icon
                            v-if="securityEvent.screened"
                            icon="fa-light fa-check"
                        />
                    </td>
                    <td>{{ securityEvent.createdRelative }}</td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            securityEvents: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/securityEvents`)
                .then((response) => {
                    this.securityEvents = response.data.securityEvents;
                });
        },
    },
};
</script>