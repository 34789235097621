<template>
    <generic-page>
        <b-container>
            <div v-if="flag">
                <h3>
                    {{ flag.name }}
                </h3>

                <div>Messages that triggered flag</div>
                <b-table-simple>
                    <b-tbody>
                        <b-tr
                            :key="userMessage.id"
                            v-for="userMessage in userMessages"
                        >
                            <td>
                                <user-avatar :user="userMessage.user" />
                            </td>
                            <td>
                                {{ userMessage.messageUnique.message }}
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            flag: null,
            userMessages: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async monitorFlag(flag) {
            await this.api.post(`/flag/${flag.id}/monitor`);
            this.refresh();
        },
        async ignoreFlag(flag) {
            await this.api.post(`/flag/${flag.id}/ignore`);
            this.refresh();
        },
        editUser(user) {
            this.$router.push(`/user/edit/${user.id}`);
        },
        refresh() {
            this.api.post(`/flag/${this.flagId}/edit`).then((response) => {
                this.flag = response.data.flag;
                this.userMessages = response.data.userMessages;
            });
        },
    },
    components: {},
    props: ["flagId"],
};
</script>