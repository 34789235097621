<template>
    <generic-page>
        <b-container>
            <b-alert
                show
                variant="light"
                v-if="$route.query?.screen"
                class="text-center py-5"
            >
                <h1>Screen Automatic Withdraws</h1>

                <b-btn
                    variant="primary"
                    size="lg"
                    @click="markScreened"
                    class="mt-3"
                >
                    Mark All Screened
                </b-btn>
            </b-alert>

            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1 class="mb-0">Withdrawals</h1>
                </div>
                <div>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="$route.query?.filter">
                                ({{ $route.query.filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('fulfilled')">
                            Fulfilled (default)
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('submitted')">
                            Submitted
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('issue')">
                            Issue
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('refunded')">
                            Refunded
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <b-table-simple striped class="mt-3">
                <b-tbody>
                    <b-tr
                        :key="withdraw.withdraw.id"
                        v-for="withdraw in withdraws"
                    >
                        <td>
                            <user-avatar
                                v-if="withdraw.user"
                                :user="withdraw.user"
                            />
                        </td>
                        <td>{{ withdraw.withdraw.type }}</td>
                        <td>${{ withdraw.withdraw.amountUsdFormatted }}</td>
                        <td>
                            {{ withdraw.withdraw.transferStatus }}
                        </td>
                        <td style="width: 1%">
                            <b-btn
                                variant="light"
                                size="sm"
                                class="text-primary text-truncate"
                                :to="`/withdraw/view/${withdraw.withdraw.id}`"
                            >
                                View
                            </b-btn>
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            withdraws: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        markScreened() {
            this.api
                .post("/withdraw/screened", {
                    ids: this.withdraws.map((withdraw) => withdraw.withdraw.id),
                })
                .then(() => {
                    this.$router.push("/");
                });
        },
        setFilter(filter) {
            const query = {};

            if (filter) {
                query.filter = filter;
            }

            this.$router.push({
                name: "withdrawIndex",
                query,
            });

            this.refresh();
        },
        refresh() {
            this.api
                .post("/withdraw/index", {
                    filter: this.$route.query?.filter,
                    screen: this.$route.query?.screen,
                })
                .then((response) => {
                    this.withdraws = response.data.withdraws;
                });
        },
    },
    components: {},
};
</script>