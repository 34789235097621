import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(relativeTime);
dayjs.extend(updateLocale)

// terse mode
// dayjs.updateLocale('en', {
//     relativeTime: {
//         future: "now",
//         past: "%s",
//         s: "now",
//         m: "1m",
//         mm: "%dm",
//         h: "1h",
//         hh: "%dh",
//         d: "1d",
//         dd: "%dd",
//         M: "1mo",
//         MM: "%dmo",
//         y: "1y",
//         yy: "%dy"
//     }
// })

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
});