<template>
    <b-modal centered v-model="modal" title="Confirm Ban" hide-footer>
        <b-alert variant="warning" show>
            This user cannot be banned while still being vetted
        </b-alert>

        <b-btn
            :disabled="loading"
            @click="removeAndBan"
            variant="danger"
            size="lg"
            block
            class="py-3 mt-4"
        >
            Remove Vetted and Ban
        </b-btn>

        <b-btn @click="cancel" variant="secondary" size="lg" block class="mt-4">
            Cancel
        </b-btn>
    </b-modal>
</template>

<script>
import { mapState } from "vuex";

// this is now obviated
// can ban users even when vetted
// shadowban is no longer a thing
// now were asking for a bond

export default {
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        modal: {
            get() {
                return this.$store.state.userEdit.vettedBanModal;
            },
            set(value) {
                this.$store.commit("userEdit/setVettedBanModal", value);
            },
        },
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async removeAndBan() {
            this.loading = true;

            await this.api.post(
                `/user/${this.user.model.id}/removeVettedAndBan`
            );

            this.$store.dispatch("userEdit/refresh");
            this.$store.commit("userEdit/setVettedBanModal", false);

            this.loading = false;
        },
        cancel() {
            this.$store.commit("userEdit/setVettedBanModal", false);
        },
    },
};
</script>