<template>
    <div class="preserve" v-html="highlightedContent"></div>
</template>

<style lang="scss" scoped>
.preserve {
    border: none;
    white-space: pre-wrap !important;
    word-break: break-word !important;
}

</style>

<script>
export default {
    computed: {
        highlightedContent() {
            if (!this.highlight || !this.highlight.length) {
                return this.content;
            }

            let { content } = this;

            this.highlight.forEach((keyword) => {
                content = content.replace(
                    new RegExp(keyword, "gi"),
                    (match) => `<span class='highlight'>${match}</span>`,
                );
            });

            return content;
        },
    },
    props: ["content", "highlight"],
};
</script>
