<template>
    <div class="bg-light p-3">
        <h1>Bin</h1>

        <b-table-simple v-if="bin" class="small" striped>
            <b-tbody>
                <b-tr>
                    <b-th> ID </b-th>
                    <b-td>
                        {{ bin.id }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Modern (8 digit) </b-th>
                    <b-td>
                        {{ bin.modern }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Legacy (6 digit) </b-th>
                    <b-td>
                        {{ bin.legacy }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Bank Name </b-th>
                    <b-td>
                        {{ bin.bankName }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Card Type </b-th>
                    <b-td>
                        {{ bin.cardType }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Meta </b-th>
                    <b-td>
                        <pre class="audit mb-0 small text-">{{ bin.meta }}</pre>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Created </b-th>
                    <b-td>
                        {{ bin.createdRelative }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <div class="mt-3">
            <b-btn v-if="!bin?.meta" @click="updateBin"> Update Bin </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bin: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        updateBin() {
            this.api.post(`/bin/${this.$route.query.binId}/update`).then(() => {
                this.refresh();
            });
        },
        refresh() {
            this.api
                .post(`/bin/${this.$route.query.binId}/get`)
                .then((response) => {
                    this.bin = response?.data?.bin;
                });
        },
    },
};
</script>