<template>
    <generic-page>
        <b-container>
            <h1 class="mt-3">User Reports</h1>
            <b-table-simple striped>
                <b-tbody>
                    <b-tr
                        :key="userReport.id"
                        v-for="userReport in userReports"
                    >
                        <td style="width: 1%">
                            <user-avatar :user="userReport.fromUser" />
                        </td>
                        <td class="align-middle text-center">
                            <font-awesome-icon icon="fa-light fa-arrow-right" />
                        </td>
                        <td style="width: 1%">
                            <user-avatar :user="userReport.toUser" />
                        </td>
                        <td>
                            <div>
                                {{ userReport.createdRelative }}
                            </div>
                            <h5>{{ userReport.reason }}</h5>
                            <div>{{ userReport.reportText }}</div>
                        </td>

                        <td style="width: 1%" class="align-middle">
                            <b-dropdown
                                right
                                text="Actions"
                                variant="light"
                                size="lg"
                            >
                                <b-dropdown-item
                                    @click="seenReport(userReport)"
                                >
                                    Seen
                                </b-dropdown-item>
                                <b-dropdown-divider> </b-dropdown-divider>
                                <b-dropdown-item
                                    @click="deleteReport(userReport)"
                                    variant="danger"
                                >
                                    Delete report - no impact to recipient
                                    user's reputation
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            userReports: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async seenReport(userReport) {
            await this.api.post(`/userReport/${userReport.id}/seen`);
            this.refresh();
        },
        async deleteReport(userReport) {
            await this.api.post(`/userReport/${userReport.id}/delete`);
            this.refresh();
        },
        refresh() {
            this.api
                .post("/userReport/index", {
                    filter: this.$route.query?.filter,
                })
                .then((response) => {
                    this.userReports = response.data.userReports;
                });
        },
    },
    components: {},
};
</script>