<template>
    <div class="font-weight-bold" :class="{ 'color-pink': user.flags.lgbt }">
        {{ user.orientation }}
    </div>
</template>

<style lang="scss" scoped>
.color-pink {
    color: #d63384;
}
</style>

<script>
export default {
    props: ["user"],
};
</script>