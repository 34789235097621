<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>User Agent</th>
                    <th>Created</th>
                    <th>Last Usage</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="browser.id" v-for="browser in browsers">
                    <td>{{ browser.browser.userAgent }}</td>
                    <td>{{ browser.browser.created }}</td>
                    <td>{{ browser.lastUsage }}</td>                    
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            browsers: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/browsers`)
                .then((response) => {
                    this.browsers = response.data.browsers;
                });
        },
    },
};
</script>