<template>
    <div>
        <b-textarea size="sm" v-model="loginLink" readonly></b-textarea>
        <b-btn @click="copy" variant="primary" block> Copy </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            loginLink: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.loginLink);
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/token`)
                .then((response) => {
                    this.loginLink = response.data.loginLink;
                });
        },
    },
};
</script>