<template>
    <div>
        <b-alert v-if="trainingWheels" variant="info" show class="small mt-3">
            Cities is a history of their GPS locations, the system will choose
            the closest city. Occasionally, the system can choose the wrong
            country, for exmaple if they live in South Malaysia near the
            Singapore border, it may put them in Singapore since a more
            significant city. Feel free to reassign anyone you see with MY
            language in that kind of situation.

            <div class="mt-3">
                City can easily be faked by even the unskilled scammer, in fact,
                Chrome has built-in functionality to do so for testing. If you
                notice a user is in a city center of a well know city like Los
                Angeles, Paris, Amsterdam, etc. it could be a sign they're a
                scammer, but its only one indication, this fact alone can not be
                relied on to determine if they're scamming
            </div>
            <div class="mt-3">
                Real users who live in a large well known city center will
                appear to be near other scammers, since many scammers have
                historically chose random places on a map near large metros,
                keep this in mind as well, and remain skeptical. Los Angeles,
                San Francisco, and Vancouver are very common locations where we
                have users and lots of scammers faking their locations
            </div>
        </b-alert>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th style="width: 1%"></th>
                    <th>City</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="city.id" v-for="city in cities">
                    <td>
                        <b-btn
                            @click="setCity(city)"
                            size="sm"
                            variant="light"
                            class="text-truncate"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-map-marker"
                            />
                            Set Location
                        </b-btn>
                    </td>
                    <td>
                        <img :src="city.flagUrl" />
                        {{ city.cityName }},
                        {{ city.countryName }}
                    </td>
                    <td>{{ city.createdRelative }}</td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            cities: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
        ...mapState("user", ["trainingWheels"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async setCity(city) {
            await this.api.post(
                `/user/${this.user.model.id}/city/${city.cityId}`
            );

            this.$store.dispatch("userEdit/refresh");
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/cities`)
                .then((response) => {
                    this.cities = response.data.cities;
                });
        },
    },
};
</script>