<template>
    <font-awesome-icon
        icon="fa-solid fa-circle"
        :class="`text-${user.statusVariant}`"
    ></font-awesome-icon>
</template>

<script>
export default {
    props: ["user"],
};
</script>