<template>
    <b-alert
        variant="success"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container class="mt-3">
            <div v-if="user.flags.isAfrican" class="p-3 bg-dark rounded">
                <h1>Africa Creator Criteria</h1>

                <div>
                    Due to overwhelming demand from Africa, we're only accepting
                    the top 10% of users for now.
                </div>
                <div class="mt-3">
                    If they are not in the top 10%, mark them as "Disregard" to
                    skip moderation
                </div>

                <b-btn
                    :disabled="pause"
                    variant="secondary"
                    @click="disregard"
                    size="lg"
                    class="px-5 mt-3"
                >
                    Disregard
                </b-btn>
            </div>
        </b-container>

        <b-container
            class="d-flex align-items-center justify-content-around mt-3"
        >
            <div>
                <h1 class="mb-0">Vetting</h1>
                <div>Mark vetted by clicking on "Vetted" below</div>
                <div key="">
                    Click the button below the specific video that passed
                </div>
                <font-awesome-icon icon="fa-light fa-arrow-down" />
            </div>
            <div>
                <div>
                    <b-dropdown text="More Options">
                        <b-dropdown-item
                            :disabled="pause"
                            @click="idRequired"
                            class="py-3"
                        >
                            Photo ID Required
                            <div class="small text-secondary">
                                Suspected minors, wrong gender
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>

                    <b-btn-group class="w-100 mt-3">
                        <b-btn
                            :disabled="pause"
                            @click="ugly"
                            class="border"
                            variant="secondary"
                        >
                            Ugly
                        </b-btn>
                        <b-btn
                            :disabled="pause"
                            @click="appearanceCute"
                            class="border"
                            variant="secondary"
                        >
                            <font-awesome-icon
                                v-if="user.model.appearance === 'cute'"
                                icon="fa-light fa-check"
                            />
                            Cute
                        </b-btn>
                        <b-btn
                            :disabled="pause"
                            @click="appearanceHot"
                            class="border"
                            variant="secondary"
                        >
                            <font-awesome-icon
                                v-if="user.model.appearance === 'hot'"
                                icon="fa-light fa-check"
                            />
                            Hot
                        </b-btn>
                    </b-btn-group>
                </div>

                <div class="mt-4">
                    <b-btn
                        v-if="user.flags.isBanned"
                        :disabled="pause"
                        variant="primary"
                        href="/vetting"
                        size="lg"
                        class="px-5"
                    >
                        Next
                    </b-btn>
                    <b-btn
                        v-else
                        :disabled="pause"
                        variant="danger"
                        @click="ban"
                        size="lg"
                        class="px-5"
                    >
                        Ban
                    </b-btn>
                </div>
            </div>
        </b-container>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            pause: true,
        };
    },
    mounted() {
        setTimeout(() => {
            this.pause = false;
        }, 1000);
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async idRequired() {
            await this.api.post(`/vetting/${this.user.model.id}/idRequired`);
            this.$store.dispatch("userEdit/refresh");
        },
        async appearanceCute() {
            await this.api.post(`/screen/${this.user.model.id}/appearanceCute`);
            this.$store.dispatch("userEdit/refresh");
        },
        async appearanceHot() {
            await this.api.post(`/screen/${this.user.model.id}/appearanceHot`);
            this.$store.dispatch("userEdit/refresh");
        },
        async ban() {
            await this.$store.dispatch("userEdit/ban", {
                success: () => {
                    this.$router.push("/vetting");
                },
            });
        },
        async ugly() {
            await this.api.post(`/vetting/${this.user.model.id}/ugly`);
            this.$router.push("/vetting");
        },
        async disregard() {
            await this.api.post(`/vetting/${this.user.model.id}/disregard`);
            this.$router.push("/vetting");
        },
    },
};
</script>