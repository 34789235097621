<template>
    <generic-page>
        <b-container>
            <b-alert
                v-if="disableAutomaticWithdraw"
                variant="info"
                show
                class="mt-3"
            >
                <h1>Automatic Withdraw Disabled</h1>

                All withdrawals from this user will be held for manual review
                until re-enabled

                <div class="mt-3">
                    <b-btn @click="enableAutomatic" variant="primary">
                        Enable Automatic Withdraw
                    </b-btn>
                </div>
            </b-alert>

            <b-alert variant="danger" show v-if="user?.model?.scheduledDelete">
                <h1>
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    User has deletion scheduled
                </h1>
            </b-alert>
            <div v-if="withdraw" class="mt-3">
                <withdraw-status />

                <div
                    class="d-flex align-items-center justify-content-between mt-3"
                >
                    <h1>Withdrawal #{{ meta.withdrawCount }}</h1>
                    <b-btn
                        target="_blank"
                        :href="withdraw.oldSystemUrl"
                        variant="light"
                        size="sm"
                        class="border mr-1"
                    >
                        Old System
                    </b-btn>
                </div>

                <withdraw-logs />

                <b-row class="mt-3">
                    <b-col cols="6"> <withdraw-details /> </b-col>
                    <b-col cols="6">
                        <withdraw-actions />
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

import WithdrawStatus from "@/components/withdraw/WithdrawStatus";
import WithdrawDetails from "@/components/withdraw/WithdrawDetails";
import WithdrawActions from "@/components/withdraw/WithdrawActions";
import WithdrawLogs from "@/components/withdraw/WithdrawLogs";

export default {
    mounted() {
        this.$store.dispatch("withdraw/get", {
            id: this.$route.params.id,
        });
    },
    methods: {
        enableAutomatic() {
            this.api
                .post(`/user/${this.user.model.id}/enableAutomaticWithdraw`, {})
                .then(() => {
                    this.$store.dispatch("withdraw/refresh");
                });
        },
    },
    computed: {
        ...mapState("withdraw", [
            "withdraw",
            "meta",
            "user",
            "disableAutomaticWithdraw",
        ]),
    },
    components: {
        WithdrawActions,
        WithdrawDetails,
        WithdrawLogs,
        WithdrawStatus,
    },
};
</script>