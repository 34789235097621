<template>
    <generic-page>
        <b-container>
            <generic-loading />
        </b-container>
    </generic-page>
</template>
<script>
export default {
    mounted() {
        this.refresh();
    },
    watch: {
        $route() {
            this.refresh();
        },
    },
    methods: {
        refresh() {
            this.api.post("/review/next").then((response) => {
                const userId = response?.data?.userId;

                if (userId) {
                    this.$router.push(`/user/edit/${userId}?review=1`);
                } else {
                    this.$router.push("/");
                }
            });
        },
    },
};
</script>