<template>
    <generic-page>
        <b-container>
            <h1>Review Queue</h1>
            <b-table-simple class="small" striped bordered>
                <b-thead>
                    <b-tr>
                        <b-th> User</b-th>
                        <b-th> Info</b-th>
                        <b-th></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr :key="user.model.id" v-for="user in filteredUsers">
                        <b-td>
                            <user-avatar :user="user" />
                            <div
                                class="d-inline"
                                :key="photo.id"
                                v-for="photo in user.photos"
                            >
                                <img :src="photo.search" />
                            </div>
                        </b-td>
                        <b-td>
                            <coin-badge
                                v-if="user.totalCreditsEarned"
                                :amount="user.totalCreditsEarned"
                            />
                        </b-td>
                        <b-td>
                            <b-btn variant="light" @click="ugly(user)">
                                Ugly
                            </b-btn>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>
    </generic-page>
</template>
<script>
export default {
    data() {
        return {
            users: null,
            hideUserIds: [],
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        filteredUsers() {
            return this.users?.filter(
                (user) => !this.hideUserIds.includes(user.model.id)
            );
        },
    },
    methods: {
        ugly(user) {
            this.hideUserIds.push(user.model.id);
            this.api.post(`/review/${user.model.id}/ugly`);
        },
        refresh() {
            this.api.post("/review/queue").then((response) => {
                this.users = response.data.users;
            });
        },
    },
};
</script>