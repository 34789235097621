<template>
    <b-nav-item-dropdown text="Gender">
        <b-dropdown-item @click="reassignGender(1, 2)">M:F</b-dropdown-item>
        <b-dropdown-item @click="reassignGender(2, 1)">F:M</b-dropdown-item>
        <b-dropdown-item @click="reassignGender(1, 1)">M:M</b-dropdown-item>
        <b-dropdown-item @click="reassignGender(2, 2)">F:F</b-dropdown-item>
        <b-dropdown-item @click="reassignGender(1, 3)">M:T</b-dropdown-item>
        <b-dropdown-item @click="reassignGender(3, 1)">T:M</b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user", "tabs"]),
    },
    methods: {
        async reassignGender(gender, seeking) {
            await this.api.post(`/user/${this.user.model.id}/reassignGender`, {
                gender,
                seeking,
            });

            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>


