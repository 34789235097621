<template>
    <div>
        <div class="position-relative overflow-hidden">
            <div
                v-if="isPrimary(photo)"
                class="position-absolute"
                style="top: 0; right: 0; z-index: 1"
            >
                <font-awesome-icon
                    icon="fa-solid fa-star"
                    class="text-warning"
                />
            </div>
            <div
                v-else-if="photo.rejectReason"
                class="position-absolute bg-dark px-1 extra-small text-danger text-capitalize"
                style="top: 0; right: 0; z-index: 1"
            >
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ photo.rejectReason }}
            </div>
            <b-img
                @click="modal = true"
                :class="{ blurred: isBlurred(photo) }"
                class="rounded border fake-link"
                :src="photo.medium"
                fluid
            />
            <div
                class="position-absolute bg-dark opacity-90"
                style="bottom: 0; z-index: 1"
            >
                <b-btn
                    v-if="photo.approved && !photo.deleted"
                    @click="rejectPhoto(photo)"
                    variant="light"
                    class="p-0 px-1 text-danger"
                    block
                >
                    <font-awesome-icon icon="fa-solid fa-thumbs-down" />
                </b-btn>
                <b-btn
                    v-if="!photo.approved"
                    @click="approvePhoto(photo)"
                    variant="light"
                    class="p-0 px-1 text-primary"
                    block
                >
                    <font-awesome-icon icon="fa-solid fa-thumbs-up" />
                </b-btn>
            </div>
        </div>
        <b-modal centered v-model="modal" title="Manage Photo" hide-footer>
            <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0">{{ photo.id }}</h3>
                <b-btn
                    @click="deletePhoto(photo)"
                    variant="light"
                    class="text-danger"
                    size="sm"
                >
                    <font-awesome-icon icon="fa-light fa-times" />
                    Delete
                </b-btn>
            </div>

            <b-btn
                v-if="photo.deleted"
                @click="restore(photo)"
                variant="light"
                block
                size="lg"
                class="my-3"
            >
                Restore
            </b-btn>

            <b-btn
                v-if="photo.approved"
                @click="setPrimary(photo)"
                variant="light"
                block
                size="lg"
                class="my-3"
            >
                <font-awesome-icon
                    class="text-warning"
                    icon="fa-solid fa-star"
                />
                Set Primary
            </b-btn>

            <div>Large</div>
            <b-img :src="photo.large" fluid />
            <div>Medium</div>
            <b-img :src="photo.medium" fluid />
            <div>Search</div>
            <b-img :src="photo.search" fluid />
            <div>Small</div>
            <b-img :src="photo.small" fluid />
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.blurred {
    filter: blur(5px);
}
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        publicPhotos() {
            return this.user.photos?.filter((photo) => photo.approved);
        },
        privatePhotos() {
            return this.user.photos?.filter((photo) => !photo.approved);
        },
        ...mapState("userEdit", ["user"]),
        ...mapState("ninja", ["unblurredUserIds"]),
    },
    methods: {
        isPrimary(photo) {
            return this.user.model.primaryPhotoId === photo.id;
        },
        async setPrimary(photo) {
            await this.api.post(`/photo/${photo.id}/setPrimary`);
            this.modal = false;
            this.$store.dispatch("userEdit/refresh");
        },
        async deletePhoto(photo) {
            await this.api.post(`/photo/${photo.id}/delete`);
            this.modal = false;
            this.$store.dispatch("userEdit/refresh");
        },
        async rejectPhoto(photo) {
            await this.api.post(`/photo/${photo.id}/reject`);
            this.$store.dispatch("userEdit/refresh");
        },
        async approvePhoto(photo) {
            await this.api.post(`/photo/${photo.id}/approve`);
            this.$store.dispatch("userEdit/refresh");
        },
        async restore(photo) {
            await this.api.post(`/photo/${photo.id}/restore`);
            this.$store.dispatch("userEdit/refresh");
        },
        isBlurred(userPhoto) {
            if (this.unblurredUserIds.includes(this.user.model.id)) {
                return false;
            }

            if (userPhoto.censor) {
                return true;
            }

            return false;
        },
    },
    props: ["photo"],
};
</script>