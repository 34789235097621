<template>
    <generic-page>
        <b-container>
            <div v-if="keyword">
                <h3>
                    {{ keyword.keyword }}
                </h3>
                <div>Created {{ keyword.createdRelative }}</div>

                <b-select v-model="action" :options="actions"> </b-select>

                <div class="mt-3">
                    AI Prompt (refer to variables: "PLATFORM", "Creator user",
                    "Payer user")
                    <b-form-textarea
                        size="lg"
                        v-model="aiPrompt"
                        rows="4"
                    ></b-form-textarea>

                    <div class="small mt-3">
                        <pre>Return TRUE = userKeyword.harmless = 1</pre>
                        <pre>Return FALSE = userKeyword.harmless = 0</pre>
                        <pre>
Return anything else = userKeyword.harmless = null</pre
                        >
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                    <b-btn
                        @click="save"
                        size="lg"
                        class="mt-3"
                        variant="primary"
                    >
                        Save
                    </b-btn>

                    <b-dropdown text="Actions">
                        <b-dropdown-item
                            variant="danger"
                            @click="deleteKeyword"
                        >
                            Delete
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            aiPrompt: null,
            keyword: null,
            action: null,
            actions: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async deleteKeyword() {
            await this.api.post(`/keyword/${this.keyword.id}/delete`);
            this.$router.push("/keyword/index");
        },
        async save() {
            await this.api.post(`/keyword/${this.keyword.id}/save`, {
                aiPrompt: this.aiPrompt,
                action: this.action,
            });

            this.refresh();
        },
        refresh() {
            const keywordId = this.$route.params.id;

            this.api.post(`/keyword/${keywordId}/edit`).then((response) => {
                this.keyword = response.data.keyword;
                this.actions = response.data.actions;

                this.aiPrompt = this.keyword.aiPrompt;
                this.action = this.keyword.action;
            });
        },
    },
};
</script>