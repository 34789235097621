<template>
    <div>
        <b-alert
            v-if="user.flags.ineligible"
            variant="danger"
            show
            class="mb-0 border-0 rounded-0"
        >
            <font-awesome-icon icon="fa-duotone fa-solid fa-skull-crossbones" />
            Ineligible
        </b-alert>
        <b-alert
            v-if="user.flags.blockCreditPurchase"
            variant="danger"
            show
            class="mb-0 border-0 rounded-0"
        >
            <font-awesome-icon icon="fa-solid fa-ban" />
            Credit purchase disabled
        </b-alert>
        <b-alert
            v-if="exactMatchBannedUserCount"
            variant="danger"
            show
            class="mb-0 border-0 rounded-0"
        >
            <font-awesome-icon icon="fa-solid fa-ban" />
            {{ exactMatchBannedUserCount }} exact match users are banned
        </b-alert>
        <b-alert
            v-else-if="exactMatchUserCount"
            variant="warning"
            show
            class="mb-0 border-0 rounded-0"
        >
            <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
            {{ exactMatchUserCount }} exact match users
        </b-alert>
        <b-alert
            v-if="user.model.scheduledDelete"
            variant="danger"
            show
            class="mb-0 border-0 rounded-0"
        >
            <font-awesome-icon icon="fa-duotone fa-trash" />
            Deletion Scheduled
            <live-timestamp :timestamp="user.model.scheduledDelete" />
            ({{ user.model.scheduledDelete }})
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        exactMatchUserCount() {
            return this.$store.state.userEdit.relatedUsers?.filter(
                (relatedUser) => relatedUser.exactMatch
            )?.length;
        },
        exactMatchBannedUserCount() {
            return this.$store.state.userEdit.relatedUsers?.filter(
                (relatedUser) =>
                    relatedUser.exactMatch && relatedUser.user.flags.isBanned
            )?.length;
        },
        ...mapState("userEdit", ["user"]),
    },
};
</script>