<template>
    <b-alert
        variant="danger"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container
            v-if="recentWithdraws?.length"
            class="border border-danger p-3"
        >
            <h3>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ recentWithdraws.length }}
                Recently Fulfilled Withdrawals - Deny if Unprocessed
            </h3>
            <b-table-simple striped>
                <b-tbody>
                    <b-tr
                        :key="withdraw.id"
                        v-for="withdraw in recentWithdraws"
                    >
                        <td>{{ withdraw.type }}</td>
                        <td>${{ withdraw.amountUsdFormatted }}</td>
                        <td>
                            {{ withdraw.transferStatus }}
                        </td>
                        <td style="width: 1%">
                            <b-btn
                                variant="light"
                                size="sm"
                                class="text-primary text-truncate"
                                :to="`/withdraw/view/${withdraw.id}`"
                            >
                                View
                            </b-btn>
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>

        <b-container
            class="d-flex align-items-center justify-content-around mt-3"
        >
            <b-btn
                :disabled="pause"
                variant="success"
                @click="accept"
                size="lg"
                class="px-5"
            >
                <font-awesome-icon icon="fa-duotone fa-envelope" />
                Accept
            </b-btn>
            <div>
                <h1 class="mb-0">Immediate Deletion</h1>
            </div>
            <b-btn
                :disabled="pause"
                variant="danger"
                @click="deny"
                size="lg"
                class="px-5"
            >
                Deny
            </b-btn>
        </b-container>

        <b-container v-if="trainingWheels" class="text-center mt-3">
            <b-button
                v-b-toggle.immediateHelp
                variant="info"
                size="sm"
                class="ml-2"
            >
                Training
            </b-button>
            <b-collapse id="immediateHelp">
                <b-alert variant="info" show class="small mt-3">
                    User has requested their account to be immediately deleted.
                    Normally they have to wait for 30 days so scammers can't
                    cover their tracks.

                    <h5 class="mt-3">Accept</h5>
                    Check their account for any risks, if they seem legit,
                    accept their request which will notify them

                    <h5 class="mt-3">Deny</h5>
                    User will not be notified, will be forced to wait the entire
                    period for account deletion
                </b-alert>
            </b-collapse>
        </b-container>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            pause: true,
            recentWithdraws: null,
        };
    },
    mounted() {
        setTimeout(() => {
            this.pause = false;
        }, 1000);

        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/recentWithdraws`)
                .then((response) => {
                    this.recentWithdraws = response.data.recentWithdraws;
                    this.pause = false;
                });
        },
        async accept() {
            this.pause = true;
            try {
                await this.api.post(`/immediate/${this.user.model.id}/accept`);
                this.$router.push("/immediate");
            } catch (error) {
                if (error?.response?.data?.message) {
                    alert(error.response.data.message);
                }
            }
        },
        async deny() {
            this.pause = true;
            await this.api.post(`/immediate/${this.user.model.id}/deny`);
            this.$router.push("/immediate");
        },
    },
};
</script>