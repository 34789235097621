<template>
    <b-tr v-if="user.model.lastActive">
        <b-th class="bg-light" style="width: 40%"> Last Active </b-th>
        <b-td class="py-0 align-middle">
            <b-btn @click="resetLastActive" variant="light" size="sm" class="mr-1">
                <font-awesome-icon icon="fa-light fa-rotate-left" />
            </b-btn>
            <live-timestamp :timestamp="user.model.lastActive" />
        </b-td>
    </b-tr>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async resetLastActive() {
            await this.api.post(`/user/${this.user.model.id}/resetLastActive`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>