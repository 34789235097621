<template>
    <div>
        <authorize-transaction-table
            :authorizeTransactionIndex="authorizeTransactionIndex"
        />
    </div>
</template>


<script>
import { mapState } from "vuex";

import AuthorizeTransactionTable from "@/components/authorizeTransaction/AuthorizeTransactionTable";

export default {
    data() {
        return {
            authorizeTransactionIndex: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/authorizeTransactions`)
                .then((response) => {
                    this.authorizeTransactionIndex =
                        response.data.authorizeTransactionIndex;
                });
        },
    },
    components: {
        AuthorizeTransactionTable,
    },
};
</script>