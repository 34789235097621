<template>
    <div>
        <div class="position-relative overflow-hidden">
            <b-img
                @click="modal = true"
                :class="{ blurred: isBlurred(photo) }"
                class="rounded border fake-link"
                style="max-height: 100px; object-fit: cover"
                :src="photo.url"
                lazy
            />
        </div>
        <b-modal centered v-model="modal" title="View Photo" hide-footer>
            <div>Full size</div>
            <b-img :src="photo.url" fluid lazy />
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.blurred {
    filter: blur(5px);
}
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        publicPhotos() {
            return this.user.photos?.filter((photo) => photo.approved);
        },
        privatePhotos() {
            return this.user.photos?.filter((photo) => !photo.approved);
        },
        ...mapState("userEdit", ["user"]),
        ...mapState("ninja", ["unblurredUserIds"]),
    },
    methods: {
        isBlurred() {
            if (this.unblurredUserIds.includes(this.user.model.id)) {
                return false;
            }

            return true;
        },
    },
    props: ["photo"],
};
</script>