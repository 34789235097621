import api from "@/service/api";

const state = () => ({
    withdraw: null,
    meta: null,
    user: null,
    invitedByUser: null,
    logs: null,
    disableAutomaticWithdraw: null,
})

const mutations = {
    reset(state) {
        state.withdraw = null
        state.meta = null
    },
    setWithdraw(state, value) {
        state.withdraw = value;
    },
    setMeta(state, value) {
        state.meta = value;
    },
    setDisableAutomaticWithdraw(state, value) {
        state.disableAutomaticWithdraw = value;
    },
    setLogs(state, value) {
        state.logs = value;
    },
    setUser(state, value) {
        state.user = value;
    },
    setInvitedByUser(state, value) {
        state.invitedByUser = value;
    },
}

const actions = {
    async refresh({ dispatch, state }) {
        if (!state.withdraw) {
            return
        }

        dispatch('get', {
            id: state.withdraw.id
        })
    },
    async get({ commit }, { id, clear } = {}) {
        if (clear) {
            commit('reset')
        }

        try {
            const response = await api.post(`/withdraw/${id}/get`);

            commit('setWithdraw', response.data.withdraw)
            commit('setMeta', response.data.meta)
            commit('setDisableAutomaticWithdraw', response.data.disableAutomaticWithdraw)
            commit('setUser', response.data.user)
            commit('setLogs', response.data.logs)
            commit('setInvitedByUser', response.data.invitedByUser)
        } catch (error) {
            if (error?.response?.status === 404) {
                commit('setNotFound', true)
            }
        }
    },
}



export default {
    namespaced: true,
    state,
    actions,
    mutations
}
