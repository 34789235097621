const state = () => ({
    now: new Date(),
})

const mutations = {
    refreshNow(state) {
        state.now = new Date()
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
