const state = () => ({
    unblurredUserIds: [],
})

const mutations = {
    unblurUserId(state, value) {
        state.unblurredUserIds.push(value)
    },
    blurUserId(state, value) {
        state.unblurredUserIds = state.unblurredUserIds.filter(id => id !== value)
    },
}

const actions = {
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
