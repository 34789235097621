<template>
    <b-tr>
        <b-th class="bg-light" style="width: 40%"> Attribution </b-th>
        <b-td class="py-0 align-middle">
            {{ attribution }}
            <b-spinner small v-if="!attribution" />
        </b-td>
    </b-tr>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            attribution: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/attribution`)
                .then((response) => {
                    this.attribution = response?.data?.attribution;
                });
        },
    },
};
</script>