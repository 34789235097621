<template>
    <div>
        <div v-if="withdraw.fulfilledAt" class="alert alert-success">
            Withdrawal has been fulfilled
        </div>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw", "user"]),
    },
};
</script>