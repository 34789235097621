<template>
    <div>
        <manage-bin v-if="$route.query?.binId" />
        <manage-ip-address v-if="$route.query?.ipAddressId" />

        <user-filters @filtersUpdated="filtersUpdated" />

        <b-table-simple hover>
            <b-thead>
                <b-tr>
                    <b-th> </b-th>
                    <b-th>Name</b-th>
                    <b-th>Orientation</b-th>
                    <b-th>Location</b-th>
                    <b-th>Balance</b-th>
                    <b-th>Last Active</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    class="fake-link"
                    @click="editUser(user)"
                    :key="user.id"
                    v-for="user in users"
                >
                    <td :class="tdClass" style="width: 1%">
                        <img
                            class="rounded"
                            style="height: 32px; width: 32px"
                            v-if="user.primaryPhotoUrl"
                            :src="user.primaryPhotoUrl"
                        />
                    </td>
                    <td :class="tdClass">
                        <font-awesome-icon
                            icon="fa-solid fa-circle"
                            :class="`text-${user.statusVariant}`"
                        />

                        {{ user.name }}
                    </td>
                    <td :class="tdClass">
                        {{ user.orientation }}
                    </td>
                    <td :class="tdClass">
                        <img v-if="user.flagUrl" :src="user.flagUrl" />
                        {{ user.countryName }}
                    </td>
                    <td :class="tdClass">
                        <coin-badge
                            v-if="user.balance"
                            :amount="user.balance"
                        />
                    </td>
                    <td :class="tdClass">
                        {{ user.lastActiveRelative }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import UserFilters from "@/components/user/UserFilters";

import ManageIpAddress from "@/components/user/manage/ManageIpAddress";
import ManageBin from "@/components/user/manage/ManageBin";

export default {
    data() {
        return {
            tdClass: "align-middle",
            users: null,
            commandKeyIsPressed: false,
        };
    },
    mounted() {
        this.refresh();

        document.body.addEventListener("keydown", this.keyDownHandler);
        document.body.addEventListener("keyup", this.keyUpHandler);
    },
    methods: {
        filtersUpdated(query) {
            this.$router.push({
                name: "userIndex",
                query,
            });

            this.refresh();
        },
        keyDownHandler(e) {
            this.commandKeyIsPressed = e.which === 91 || e.metaKey || e.ctrlKey;
        },
        keyUpHandler() {
            this.commandKeyIsPressed = false;
        },
        setFilter(filter) {
            const query = {};

            if (filter) {
                query.filter = filter;
            }

            this.$router.push({
                name: "userIndex",
                query,
            });

            this.refresh();
        },
        editUser(user) {
            const url = `/user/edit/${user.id}`;

            if (this.commandKeyIsPressed) {
                window.open(url);
            } else {
                document.location = url;
            }
        },
        refresh() {
            this.api
                .post("/user/index", {
                    filter: this.$route.query?.filter,
                    binId: this.$route.query?.binId,
                    ipAddressId: this.$route.query?.ipAddressId,
                    countryId: this.$route.query?.countryId,
                    status: this.$route.query?.status,
                })
                .then((response) => {
                    this.users = response.data.users;
                });
        },
    },
    beforeDestroy() {
        document.body.removeEventListener("keydown", this.keyDownHandler);
        document.body.removeEventListener("keyup", this.keyUpHandler);
    },
    metaInfo() {
        return {
            title: "Users",
        };
    },
    components: {
        UserFilters,
        ManageIpAddress,
        ManageBin,
    },
};
</script>