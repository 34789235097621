<template>
    <b-nav-item-dropdown text="Fine">
        <b-dropdown-item
            :key="key"
            v-for="(userFine, key) in userFines"
            @click="selectFine(key)"
        >
            {{ userFine.title }}
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user", "userFines"]),
    },
    methods: {
        async selectFine(fine) {
            await this.api.post(`/fine/${this.user.model.id}/create`, {
                reason: fine,
            });
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>