<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>Subject</th>
                    <th>Email</th>
                    <th>Event</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="email.id" v-for="email in emails">
                    <td>{{ email.userEmailUnique.subject }}</td>
                    <td>{{ email.userEmailUnique.class }}</td>
                    <td>{{ email.event }}</td>
                    <td>{{ email.createdRelative }}</td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            emails: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/emails`)
                .then((response) => {
                    this.emails = response.data.emails;
                });
        },
    },
};
</script>