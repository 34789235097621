<template>
    <generic-page>
        <b-container>
            <div></div>

            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1 class="mt-3">Primary Photo Updates</h1>
                </div>
                <div>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="$route.query?.filter">
                                ({{ $route.query.filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('pending')">
                            Pending
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="setFilter('approvedAndUnscreened')"
                        >
                            Approved and Unscreened
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('rejected')">
                            Rejected
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div v-if="$route.query?.filter === 'approvedAndUnscreened'">
                <h3>Approved and Unscreened</h3>
                Here you are simply taking a look at pre-approved photo changed.
                In case the photo should not have been approved, edit the user
                and manually change the primary photo to the best possible
                photo. Marking screened does nothing except remove the photo
                update from the queue.
            </div>
            <div v-if="$route.query?.filter === 'pending'">
                <h3>Pending Updates</h3>
                Users have requested to change their primary photo, but needs
                manual approval to take effect
            </div>

            <div class="my-3" v-if="trainingWheels">
                <b-button
                    v-b-toggle.screeningHelp
                    variant="info"
                    size="sm"
                    class="ml-2"
                >
                    Training
                </b-button>
                <b-collapse id="screeningHelp">
                    <b-alert variant="info" show class="small mt-3">
                        <font-awesome-icon
                            icon="fa-duotone fa-info-circle"
                            size="lg"
                        />
                        Primary photo changes after screening now go through
                        review to ensure they aren't changing it to something
                        weird, or erotic. The primary photo is highly visible,
                        so this ensures we get to double check all changes
                        <div class="mt-3">
                            Ensure new primary photo meets out standards:
                        </div>
                        <ul>
                            <li>
                                Not Erotic - bikini OK, underwear NOT OK.
                                Sexual/suggestive poses not OK.
                            </li>
                            <li>only one person showing</li>
                            <li>no minors</li>
                            <li>photo matches previous face</li>
                            <li>
                                Photo is not worse, or harder to see than
                                previous
                            </li>
                        </ul>
                    </b-alert>
                </b-collapse>
            </div>

            <b-table-simple striped>
                <b-tbody>
                    <b-tr
                        :key="userPhotoUpdate.id"
                        v-for="userPhotoUpdate in filteredUserPhotoUpdates"
                    >
                        <td class="align-middle text-center">
                            <b-btn
                                v-if="userPhotoUpdate.isPending"
                                @click="reject(userPhotoUpdate)"
                                variant="danger"
                                class="text-truncate"
                                size="lg"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-thumbs-down"
                                />
                                Reject
                            </b-btn>
                        </td>
                        <td class="align-middle text-center">
                            <b-img
                                :src="userPhotoUpdate.previousUserPhoto.medium"
                                fluid
                            />
                        </td>
                        <td class="align-middle text-center" style="width: 1%">
                            <user-avatar :user="userPhotoUpdate.user" />

                            <font-awesome-icon
                                size="2x"
                                icon="fa-light fa-arrow-right"
                            />
                        </td>
                        <td class="align-middle text-center">
                            <b-img
                                :src="userPhotoUpdate.userPhoto.medium"
                                fluid
                            />
                        </td>
                        <td class="align-middle text-center">
                            <b-btn
                                v-if="userPhotoUpdate.isPending"
                                @click="approve(userPhotoUpdate)"
                                variant="primary"
                                class="text-truncate"
                                size="lg"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-thumbs-up"
                                />
                                Approve
                            </b-btn>
                            <b-btn
                                v-else-if="!userPhotoUpdate.screened"
                                @click="screened(userPhotoUpdate)"
                                variant="primary"
                                class="text-truncate"
                                size="lg"
                            >
                                Screened
                            </b-btn>
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            processedUserPhotoUpdateIds: [],
            userPhotoUpdates: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        filteredUserPhotoUpdates() {
            return this.userPhotoUpdates?.filter(
                (userPhotoUpdate) =>
                    !this.processedUserPhotoUpdateIds.includes(
                        userPhotoUpdate.id
                    )
            );
        },
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        setFilter(filter) {
            const query = {};

            if (filter) {
                query.filter = filter;
            }

            this.$router.push({
                name: "userPhotoUpdate",
                query,
            });

            this.refresh();
        },
        async screened(userPhotoUpdate) {
            this.processedUserPhotoUpdateIds.push(userPhotoUpdate.id);

            await this.api.post(
                `/userPhotoUpdate/${userPhotoUpdate.id}/screened`
            );
            this.refresh();
        },
        async approve(userPhotoUpdate) {
            this.processedUserPhotoUpdateIds.push(userPhotoUpdate.id);

            await this.api.post(
                `/userPhotoUpdate/${userPhotoUpdate.id}/approve`
            );
            this.refresh();
        },
        async reject(userPhotoUpdate) {
            this.processedUserPhotoUpdateIds.push(userPhotoUpdate.id);

            await this.api.post(
                `/userPhotoUpdate/${userPhotoUpdate.id}/reject`
            );
            this.refresh();
        },
        refresh() {
            this.api
                .post("/userPhotoUpdate/index", {
                    filter: this.$route.query?.filter,
                })
                .then((response) => {
                    this.userPhotoUpdates = response.data.userPhotoUpdates;
                });
        },
    },
    components: {},
};
</script>