<template>
    <div>
        <b-nav-item-dropdown text="Status">
            <b-dropdown-item
                variant="success"
                v-if="!user.model.vetted"
                @click="setVetted"
            >
                <font-awesome-icon icon="fa-duotone fa-thumbs-up" />
                Passed Vetting
                <div v-if="trainingWheels" class="small text-info">
                    User has been thoroughly checked
                </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="user.model.vetted" @click="removeVetted">
                <font-awesome-icon icon="fa-light fa-times" />
                Removed Vetted Status
            </b-dropdown-item>
            <b-dropdown-divider> </b-dropdown-divider>

            <b-dropdown-item
                v-if="user.model.vettingRequired"
                @click="removeVettingRequired"
            >
                Remove Vetting Required
                <div v-if="trainingWheels" class="small text-info">
                    Video will no longer be required for entry
                </div>
            </b-dropdown-item>
            <b-dropdown-item
                v-if="!user.model.vettingRequired"
                @click="vettingRequired"
            >
                Require Vetting
                <div v-if="trainingWheels" class="small text-info">
                    Video will be required for entry
                </div>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="ban" v-if="!this.user.flags.isBanned">
                <font-awesome-icon icon="fa-solid fa-ban" />
                Ban / poison
                <div v-if="trainingWheels" class="small text-info">
                    Disables all interactions, without tipping user
                </div>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="unban" variant="warning">
                <font-awesome-icon icon="fa-solid fa-circle" />
                Un-ban / clear poison
                <div v-if="trainingWheels" class="small text-info">
                    Reset their account to reactivate them
                </div>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="deleteModal = true" variant="danger">
                <font-awesome-icon icon="fa-duotone fa-trash" />
                Delete
                <div v-if="trainingWheels" class="small text-info">
                    Review delete options
                </div>
            </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-modal centered v-model="deleteModal" title="Delete User" hide-footer>
            <user-delete />
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserDelete from "@/components/user/UserDelete";

export default {
    data() {
        return {
            deleteModal: null,
        };
    },
    computed: {
        vettingStatus() {
            if (this.user.model.vetted) {
                return "Vetted";
            }

            if (this.user.model.vettingRequired) {
                return "Required";
            }

            return "Not Vetted";
        },

        ...mapState("userEdit", ["user"]),
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        async ban() {
            await this.$store.dispatch("userEdit/ban");
            this.$store.dispatch("userEdit/refresh");
        },
        async unban() {
            await this.api.post(`/user/${this.user.model.id}/unban`);
            this.$store.dispatch("userEdit/refresh");
        },
        async ghost() {
            await this.api.post(`/user/${this.user.model.id}/ghost`);
            this.$store.dispatch("userEdit/refresh");
        },
        async unghost() {
            await this.api.post(`/user/${this.user.model.id}/unghost`);
            this.$store.dispatch("userEdit/refresh");
        },
        async setVetted() {
            await this.api.post(`/user/${this.user.model.id}/vetted`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeVetted() {
            await this.api.post(`/user/${this.user.model.id}/removeVetted`);
            this.$store.dispatch("userEdit/refresh");
        },
        async vettingRequired() {
            await this.api.post(`/user/${this.user.model.id}/vettingRequired`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeVettingRequired() {
            await this.api.post(
                `/user/${this.user.model.id}/removeVettingRequired`
            );
            this.$store.dispatch("userEdit/refresh");
        },
    },
    components: {
        UserDelete,
    },
};
</script>