<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">History</h5>
            <b-btn :disabled="refreshing" variant="light" pill @click="refresh">
                <div v-if="refreshing">
                    <b-spinner small variant="dark" />
                </div>
                <div v-else>
                    <font-awesome-icon icon="fa-light fa-arrows-rotate" />
                </div>
            </b-btn>
        </div>
        <b-table-simple class="mt-2 small" bordered>
            <thead>
                <th>Summary</th>
                <th>Amount</th>
                <th>When</th>
            </thead>
            <tbody>
                <tr :key="transaction.id" v-for="transaction in transactions">
                    <td class="bg-light w-66">
                        {{ transaction.description }}
                    </td>
                    <td :class="`text-${transaction.variant}`">
                        {{ transaction.amount }}
                    </td>
                    <td class="">
                        {{ transaction.createdRelative }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            transactions: null,
            refreshing: false,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        refresh() {
            this.refreshing = true;

            this.api
                .post(`/user/${this.user.model.id}/walletTransactions`)
                .then((response) => {
                    this.transactions = response.data.transactions;
                    this.refreshing = false;
                });
        },
    },
};
</script>