<template>
    <b-alert
        v-if="!user.flags.allowNearbyHotspot && nearbyHotspots?.length"
        variant="warning"
        show
    >
        <h5>Nearby Hotspots Detected</h5>

        <b-table-simple class="small" striped bordered>
            <b-thead>
                <b-tr>
                    <b-th> </b-th>
                    <b-th> Distance </b-th>
                    <b-th> Created </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    :key="nearbyHotspot.id"
                    v-for="nearbyHotspot in nearbyHotspots"
                >
                    <b-td>
                        {{ nearbyHotspot.id }}
                    </b-td>
                    <b-td>
                        {{ nearbyHotspot.distance }}
                    </b-td>
                    <b-td>
                        {{ nearbyHotspot.createdRelative }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-btn @click="allowNearbyHotspot" variant="light">
            Allow Nearby Hotspots
        </b-btn>
    </b-alert>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            nearbyHotspots: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async allowNearbyHotspot() {
            await this.api.post(
                `/user/${this.user.model.id}/allowNearbyHotspot`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/nearbyHotspots`)
                .then((response) => {
                    this.nearbyHotspots = response.data.nearbyHotspots;
                });
        },
    },
};
</script>