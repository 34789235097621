<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>Timezone</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="timezone.id" v-for="timezone in timezones">
                    <td>{{ timezone.timezone }}</td>
                    <td>{{ timezone.createdRelative }}</td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            timezones: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/timezones`)
                .then((response) => {
                    this.timezones = response.data.timezones;
                });
        },
    },
};
</script>