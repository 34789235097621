<template>
    <generic-page>
        <b-container>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1>Flags</h1>
                </div>
                <div>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="filter">
                                ({{ filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('monitor')">
                            Monitor
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('ignore')">
                            Ignore
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <b-modal
                centered
                v-model="modal"
                title="Nearby Users"
                hide-footer
                body-class="p-0"
            >
                <div v-if="editFlag">
                    <div class="d-flex justify-content-between p-3">
                        <b-btn
                            variant="light"
                            size="lg"
                            class="text-danger text-truncate"
                            @click="ignoreFlag(editFlag)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Ignore
                        </b-btn>

                        <b-btn
                            variant="light"
                            size="lg"
                            class="text-primary text-truncate"
                            @click="monitorFlag(editFlag)"
                        >
                            <font-awesome-icon icon="fa-light fa-eye" />
                            Monitor
                        </b-btn>
                    </div>

                    <flag-edit :flagId="editFlag.id" />
                </div>
            </b-modal>

            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <b-table-simple striped>
                    <b-tbody>
                        <b-tr :key="flag.id" v-for="flag in filteredFlags">
                            <td style="width: 1%" class="align-middle">
                                <b-btn
                                    variant="light"
                                    @click="setEditFlag(flag)"
                                >
                                    <font-awesome-icon
                                        icon="fa-light fa-pencil"
                                    />
                                </b-btn>
                            </td>
                            <td class="lead align-middle">
                                {{ flag.name }}
                            </td>
                            <td style="width: 1%">
                                <font-awesome-icon
                                    v-if="flag.monitor"
                                    class="text-primary"
                                    icon="fa-light fa-eye"
                                />
                                <font-awesome-icon
                                    v-if="flag.ignore"
                                    class="text-danger"
                                    icon="fa-light fa-times"
                                />
                                <b-btn
                                    v-if="!flag.screened"
                                    variant="light"
                                    size="lg"
                                    class="text-primary text-truncate"
                                    @click="monitorFlag(flag)"
                                >
                                    <font-awesome-icon icon="fa-light fa-eye" />
                                    Monitor
                                </b-btn>
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>

                <div class="text-center py-5">
                    <b-btn
                        variant="light"
                        size="lg"
                        class="text-danger text-truncate px-5 py-3"
                        @click="ignoreAll"
                    >
                        <font-awesome-icon icon="fa-light fa-times" />
                        Ignore All
                    </b-btn>
                </div>
            </div>
        </b-container>
    </generic-page>
</template>


<script>
import FlagEdit from "@/components/flag/FlagEdit";

export default {
    data() {
        return {
            loading: false,
            modal: false,
            editFlag: null,
            filter: null,
            flags: null,
            screenedFlags: [],
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        filteredFlags() {
            return this.flags?.filter(
                (flag) => !this.screenedFlags.includes(flag.id)
            );
        },
    },
    methods: {
        setEditFlag(flag) {
            this.editFlag = flag;
            this.modal = true;
        },
        setFilter(filter) {
            this.filter = filter;
            this.refresh();
        },
        async ignoreFlag(flag) {
            this.screenedFlags.push(flag.id);
            await this.api.post(`/flag/${flag.id}/ignore`);
            this.modal = false;
        },
        async monitorFlag(flag) {
            this.screenedFlags.push(flag.id);
            await this.api.post(`/flag/${flag.id}/monitor`);
            this.modal = false;
        },
        async ignoreAll() {
            const flagIds = this.filteredFlags.map((flag) => flag.id);

            await this.api.post(`/flag/bulkIgnore`, {
                flagIds,
            });

            this.refresh();
        },
        refresh() {
            //reset screened
            this.screenedFlags = [];
            this.loading = true;

            this.api
                .post("/flag/index", {
                    filter: this.filter,
                })
                .then((response) => {
                    this.flags = response.data.flags;
                    this.loading = false;
                });
        },
    },
    components: {
        FlagEdit,
    },
};
</script>