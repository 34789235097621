<template>
    <b-container>
        <div :key="paidShare.id" v-for="{ paidShare, user } in paidShares">
            <div
                v-if="isUnscreened(paidShare)"
                class="my-3 p-3 border rounded bg-light"
            >
                <div
                    class="bg-dark d-flex align-items-center justify-content-between"
                >
                    <button
                        @click="rejected(paidShare)"
                        type="button"
                        class="btn rounded-0 btn-danger btn-lg px-5"
                    >
                        <i class="fa-duotone fa-thumbs-down"></i>
                        Reject
                    </button>

                    <h5 class="mb-0 ms-3">
                        {{ paidShare.type }}
                    </h5>

                    <button
                        @click="verified(paidShare)"
                        type="button"
                        class="btn rounded-0 btn-primary btn-lg px-5"
                    >
                        <i class="fa-duotone fa-thumbs-up"></i>
                        Verified
                    </button>
                </div>
                <div class="p-3">
                    <div>
                        {{ paidShare.createdRelative }}
                        <user-avatar :user="user" />
                    </div>

                    <a
                        target="_blank"
                        :href="paidShare.verifyUrl"
                        class="btn btn-primary btn-lg w-100 mt-3"
                    >
                        Open Link
                    </a>

                    <div class="input-group mt-3">
                        <input
                            class="form-control bg-dark border-0 rounded-0"
                            :value="paidShare.verifyUrl"
                        />
                        <button class="btn btn-primary">Copy</button>
                    </div>
                </div>
            </div>
        </div>

        <b-alert v-if="trainingWheels" variant="info" show class="small mt-3">
            <font-awesome-icon icon="fa-duotone fa-info-circle" size="lg" />
            Users can earn bonus credits by sharing links on specified websites.
            We manually verify the placement of the link to give them the
            credit. Open the link, make sure you can see they actually shared
            their invite link. You may need to scroll down to see the post if
            they linked to their profile directly
        </b-alert>
    </b-container>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            pauseScreening: false,
            paidShares: null,
            screened: [],
        };
    },
    computed: {
        ...mapState("user", ["trainingWheels"]),
    },
    created() {
        this.api.post("/paidShare/index").then((response) => {
            this.paidShares = response.data.paidShares;
        });
    },
    methods: {
        completeScreening(paidShare) {
            this.screened.push(paidShare.id);

            this.pauseScreening = true;

            setTimeout(() => {
                this.pauseScreening = false;
            }, 500);
        },
        isUnscreened(paidShare) {
            return !this.screened.includes(paidShare.id);
        },
        verified(paidShare) {
            this.completeScreening(paidShare);
            this.api.post(`/paidShare/${paidShare.id}/verified`);
        },
        rejected(paidShare) {
            this.completeScreening(paidShare);
            this.api.post(`/paidShare/${paidShare.id}/rejected`);
        },
    },
    components: {},
};
</script> 