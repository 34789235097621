<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h6>
                <font-awesome-icon
                    icon="fa-duotone fa-globe"
                    class="text-primary"
                />
                Public Photos
            </h6>

            <b-dropdown right text="Photo Actions" variant="light" size="sm">
                <b-dropdown-item @click="restoreAll">
                    Restore All Photos
                </b-dropdown-item>
                <b-dropdown-item @click="removePrimary">
                    Remove Primary Photo
                </b-dropdown-item>
                <b-dropdown-divider> </b-dropdown-divider>
                <b-dropdown-item @click="nuke" variant="warning">
                    <font-awesome-icon icon="fa-duotone fa-circle-radiation" />
                    Nuke All Photos, Re-educate
                    <div v-if="trainingWheels" class="small text-info">
                        Delete all of their photos and email the user letting
                        them know not to use AI or Fake photos
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <b-row no>
            <b-col
                cols="4"
                md="2"
                :key="photo.id"
                v-for="photo in publicPhotos"
            >
                <user-photo-item :photo="photo" />
            </b-col>
        </b-row>

        <div v-if="privatePhotos.length">
            <div class="d-flex align-items-center justify-content-between mt-3">
                <h6>
                    <font-awesome-icon
                        icon="fa-duotone fa-lock"
                        class="text-warning"
                    />
                    Private Photos
                </h6>
                <b-btn
                    v-if="isBlurred"
                    @click="blurUser"
                    variant="light"
                    size="sm"
                >
                    Add Blur
                </b-btn>
                <b-btn v-else @click="unblurUser" variant="light" size="sm">
                    Remove Blur
                </b-btn>
            </div>

            <b-row no>
                <b-col
                    cols="4"
                    md="2"
                    :key="photo.id"
                    v-for="photo in privatePhotos"
                >
                    <user-photo-item :photo="photo" />
                </b-col>
            </b-row>
        </div>

        <div v-if="deletedPhotos.length">
            <div class="d-flex align-items-center justify-content-between mt-3">
                <h6>
                    <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="text-danger"
                    />
                    Deleted Photos
                </h6>
            </div>

            <b-row no>
                <b-col
                    cols="4"
                    md="2"
                    :key="photo.id"
                    v-for="photo in deletedPhotos"
                >
                    <user-photo-item class="opacity-50" :photo="photo" />
                </b-col>
            </b-row>
        </div>

        <div v-if="pendingPhotos.length">
            <div class="d-flex align-items-center justify-content-between mt-3">
                <h6>Pending Photos</h6>
            </div>

            <b-row no>
                <b-col
                    cols="4"
                    md="2"
                    :key="photo.id"
                    v-for="photo in pendingPhotos"
                >
                    <user-photo-item :photo="photo" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import UserPhotoItem from "@/components/user/photo/UserPhotoItem";

export default {
    computed: {
        isBlurred() {
            return this.unblurredUserIds.includes(this.user.model.id);
        },
        publicPhotos() {
            return this.user.photos?.filter(
                (photo) => photo.approved && !photo.deleted
            );
        },
        privatePhotos() {
            return this.user.photos?.filter(
                (photo) => !photo.approved && !photo.pending && !photo.deleted
            );
        },
        pendingPhotos() {
            return this.user.photos?.filter(
                (photo) => photo.pending && !photo.deleted
            );
        },
        deletedPhotos() {
            return this.user.photos?.filter((photo) => photo.deleted);
        },
        ...mapState("userEdit", ["user"]),
        ...mapState("ninja", ["unblurredUserIds"]),
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        async nuke() {
            await this.api.post(`/photo/${this.user.model.id}/nuke`);
            this.$store.dispatch("userEdit/refresh");
        },
        async restoreAll() {
            await this.api.post(`/photo/${this.user.model.id}/restoreAll`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removePrimary() {
            await this.api.post(`/photo/${this.user.model.id}/removePrimary`);
            this.$store.dispatch("userEdit/refresh");
        },
        unblurUser() {
            this.$store.commit("ninja/unblurUserId", this.user.model.id);
        },
        blurUser() {
            this.$store.commit("ninja/blurUserId", this.user.model.id);
        },
    },
    components: {
        UserPhotoItem,
    },
};
</script>