<template>
    <b-nav-item-dropdown text="Email">
        <b-dropdown-item @click="getPaidToChat">
            Get Paid to Chat (FB Lead)
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async getPaidToChat() {
            await this.api.post(`/email/${this.user.model.id}/getPaidToChat`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>