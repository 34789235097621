<template>
    <div>
        <h3>Actions</h3>
        <div>
            <div v-if="withdraw.type === 'wise'">
                <withdraw-type-wise />
            </div>

            <div v-if="withdraw.type === 'btc'">
                <withdraw-type-bitcoin />
            </div>

            <div v-if="withdraw.type === 'paypal'">
                <withdraw-type-paypal />
            </div>

            <div
                v-if="!withdraw.fulfilledAt"
                class="alert alert-primary text-center mt-5"
            >
                <h3>Click after sending money, user will be notified</h3>
                <div class="py-3">
                    <i class="fas fa-arrow-down fa-2x"></i>
                </div>
                <a class="btn btn-primary btn-lg w-100" @click="markFulfilled">
                    Mark Fulfilled
                </a>
            </div>

            <div v-if="withdraw.editable">
                <b-button
                    v-b-toggle.problem
                    variant="light"
                    class="text-danger"
                    block
                >
                    Houston we have a problem
                </b-button>
                <b-collapse id="problem">
                    <b-alert variant="warning" show class="mt-5">
                        Send the credits back to the user, and cancel the
                        withdrawal. They can resubmit it again, feel free to use
                        this if they entered something wrong, or need to
                        resubmit it for whatever reason.
                        <div class="mt-3">
                            <b-btn
                                variant="light"
                                @click="confirmCancel(withdraw)"
                                >Cancel (refunds credits)</b-btn
                            >
                        </div>
                    </b-alert>
                    <b-alert variant="danger" show class="mt-5">
                        Withdrawal will not be fulfilled, user will not be
                        notified, it will simply remove it from the list of
                        withdrawals to process, user will be able to submit
                        another withdrawal once they have enough credits.
                        <div class="mt-3">
                            <a
                                class="btn btn-danger"
                                @click.prevent="confirmDelete(withdraw)"
                                >Delete (Revokes Credits)</a
                            >
                        </div>
                    </b-alert>
                </b-collapse>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import WithdrawTypeWise from "@/components/withdraw/type/WithdrawTypeWise";
import WithdrawTypePaypal from "@/components/withdraw/type/WithdrawTypePaypal";
import WithdrawTypeBitcoin from "@/components/withdraw/type/WithdrawTypeBitcoin";

export default {
    methods: {
        async markFulfilled() {
            await this.api.post(`/withdraw/${this.withdraw.id}/fulfilled`);
            this.$store.dispatch("withdraw/refresh");
        },
        async confirmCancel() {
            if (confirm("Are you sure?")) {
                await this.api.post(`/withdraw/${this.withdraw.id}/cancel`);
                this.$store.dispatch("withdraw/refresh");
            }
        },
        async confirmDelete() {
            if (confirm("Are you sure?")) {
                await this.api.post(`/withdraw/${this.withdraw.id}/delete`);
                this.$store.dispatch("withdraw/refresh");
            }
        },
    },
    computed: {
        ...mapState("withdraw", ["withdraw", "user"]),
    },
    components: {
        WithdrawTypeWise,
        WithdrawTypePaypal,
        WithdrawTypeBitcoin,
    },
};
</script>