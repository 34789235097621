<template>
    <div>
        <h3>Bitcoin</h3>
        <div class="my-3">
            <code>{{ withdraw.btcAddress }}</code>
        </div>
        <button
            class="btn btn-primary btn-lg w-100"
            @click="copyText(withdraw.btcAddress)"
        >
            Copy
        </button>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw"]),
    },
    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
    },
};
</script>