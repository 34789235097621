<template>
    <div>
        <b-alert variant="danger" v-if="authorizeTransaction.voided" show>
            <font-awesome-icon icon="fa-solid fa-ban" />
            Transaction was voided
        </b-alert>
        <div class="d-flex align-items-center justify-content-between mt-3">
            <h1>
                Authorize Transaction ID
                {{ authorizeTransaction.id }}
            </h1>

            <div></div>
        </div>

        <b-table-simple class="small" striped>
            <b-tbody>
                <b-tr>
                    <b-th> ID </b-th>
                    <b-td>
                        {{ authorizeTransaction.id }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Success </b-th>
                    <b-td>
                        <div v-if="authorizeTransaction.successful">
                            <font-awesome-icon
                                icon="fa-light fa-check-circle"
                                class="text-success"
                            />
                        </div>
                        <div v-else>
                            <font-awesome-icon
                                icon="fa-solid fa-times"
                                class="text-danger"
                            />
                            {{ authorizeTransaction.errorMessage }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Amount </b-th>
                    <b-td> ${{ authorizeTransaction.amount }} </b-td>
                </b-tr>
                <b-tr>
                    <b-th> AVS </b-th>
                    <b-td>
                        <h5>
                            {{ authorizeTransaction.avsResponse.title }}
                        </h5>
                        {{ authorizeTransaction.avsResponse.description }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Postal Code </b-th>
                    <b-td>
                        {{ authorizeTransaction.postalCode }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Created </b-th>
                    <b-td>
                        <live-timestamp
                            :timestamp="authorizeTransaction.created"
                        />
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
export default {
    props: ["authorizeTransaction"],
};
</script>