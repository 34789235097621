<template>
    <div>
        <div v-if="deleted">
            <h3 class="text-success">
                <font-awesome-icon icon="fa-light fa-check" />
                Deleted
            </h3>
            <b-btn block size="lg" variant="primary" to="/" v-if="deleted">
                Dashboard
            </b-btn>
        </div>
        <div v-else>
            <h5 class="text-center mt-2">
                How do you want to delete the user?
            </h5>

            <b-row class="mt-4">
                <b-col>
                    <b-btn block variant="light" @click="silent">
                        Delete Silently
                    </b-btn>
                </b-col>
                <b-col>
                    <b-btn
                        block
                        variant="light"
                        class="text-warning"
                        @click="notify"
                    >
                        <font-awesome-icon icon="fa-duotone fa-envelope" />
                        Delete and Notify
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            deleted: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        silent() {
            this.api
                .post(`/delete/${this.user.model.id}/silent`)
                .then(() => {
                    this.deleted = true;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        alert(error.response.data.message);
                    }
                });
        },
        notify() {
            this.api
                .post(`/delete/${this.user.model.id}/notify`)
                .then(() => {
                    this.deleted = true;
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        alert(error.response.data.message);
                    }
                });
        },
    },
};
</script>