<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>Platform</th>
                    <th>Status</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="device.id" v-for="device in devices">
                    <td>{{ device.hardwareId }}</td>
                    <td>{{ device.platform }}</td>
                    <td :class="{ 'text-danger': device.banned }">
                        <i
                            v-if="device.banned"
                            class="fa-duotone fa-triangle-exclamation text-danger"
                        ></i>
                        {{ device.status }}
                    </td>
                    <td>{{ device.createdRelative }}</td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            devices: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/devices`)
                .then((response) => {
                    this.devices = response.data.devices;
                });
        },
    },
};
</script>