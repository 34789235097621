import Vue from 'vue'
import Vuex from 'vuex'

// dynamically load all in ./modules/*.js
// needed for HMR with Vuex

function dynamicallyLoadAllModules() {
    const context = require.context("./modules", false, /([a-z_]+)\.js$/i)

    const modules = context
        .keys()
        .map((key) => ({ key, name: key.match(/([a-z_]+)\.js$/i)[1] }))
        .reduce(
            (modules, { key, name }) => ({
                ...modules,
                [name]: context(key).default
            }),
            {}
        )

    return { context, modules }
}

const { context, modules } = dynamicallyLoadAllModules()

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules,
    strict: debug,
});

if (module.hot) {
    // Hot reload whenever any module changes.
    module.hot.accept(context.id, () => {
        const { modules } = dynamicallyLoadAllModules()

        store.hotUpdate({
            modules
        })
    })
}

export default store