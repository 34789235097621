import axios from "axios";

const api = axios.create({
    method: 'post',
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.response.use(undefined, function (error) {
    return Promise.reject(error);
})

export default api;