<template>
    <div>
        <div v-if="sentPhotos?.length">
            <div class="d-flex align-items-center justify-content-between mt-3">
                <h6>
                    <font-awesome-icon
                        icon="fa-duotone fa-envelope"
                        class="text-info"
                    />
                    Private Message Photos
                </h6>
                <div>
                    <b-btn
                        v-if="!showAll"
                        @click="showAll = true"
                        variant="light"
                        size="sm"
                        class="mr-2"
                    >
                        Show All ({{ sentPhotos.length }})
                    </b-btn>

                    <b-btn
                        v-if="isBlurred"
                        @click="blurUser"
                        variant="light"
                        size="sm"
                    >
                        Add Blur
                    </b-btn>
                    <b-btn v-else @click="unblurUser" variant="light" size="sm">
                        Remove Blur
                    </b-btn>
                </div>
            </div>

            <b-row no>
                <b-col
                    cols="4"
                    md="2"
                    :key="sentPhoto.id"
                    v-for="sentPhoto in sentPhotosFiltered"
                >
                    {{ sentPhoto.createdRelative }}
                    <user-message-photo-item :photo="sentPhoto" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import UserMessagePhotoItem from "@/components/user/photo/UserMessagePhotoItem";

export default {
    data() {
        return {
            showAll: false,
            sentPhotos: null,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        sentPhotosFiltered() {
            if (this.showAll) {
                return this.sentPhotos;
            }

            return this.sentPhotos?.slice(0, 12);
        },
        isBlurred() {
            return this.unblurredUserIds.includes(this.user.model.id);
        },
        ...mapState("userEdit", ["user"]),
        ...mapState("ninja", ["unblurredUserIds"]),
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/sentPhotos`)
                .then((response) => {
                    this.sentPhotos = response.data.sentPhotos;
                });
        },
        unblurUser() {
            this.$store.commit("ninja/unblurUserId", this.user.model.id);
        },
        blurUser() {
            this.$store.commit("ninja/blurUserId", this.user.model.id);
        },
    },
    components: {
        UserMessagePhotoItem,
    },
};
</script>