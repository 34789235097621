<template>
    <div>
        <b-table-simple striped>
            <b-thead>
                <b-th> Type </b-th>
                <b-th> Amount </b-th>
                <b-th> Status </b-th>
                <b-th> Submitted </b-th>
            </b-thead>
            <b-tbody>
                <b-tr :key="withdraw.id" v-for="withdraw in withdraws">
                    <td>
                        {{ withdraw.type }}
                    </td>
                    <td>${{ withdraw.amountUsdFormatted }}</td>
                    <td>
                        {{ withdraw.transferStatus }}
                    </td>
                    <td>
                        <live-timestamp :timestamp="withdraw.submittedAt" />
                    </td>
                    <td>
                        <b-btn
                            :to="`/withdraw/view/${withdraw.id}`"
                            variant="light"
                            size="sm"
                        >
                            View
                        </b-btn>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            modal: false,
            withdraw: null,
            withdraws: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/withdraw`)
                .then((response) => {
                    this.withdraws = response.data.withdraws;
                });
        },
    },
    components: {},
};
</script>