import api from "@/service/api";

const state = () => ({
    loaded: false,
    countries: null,
    metas: null,
    landings: null,
    sources: null,
    tiers: null,
    filtersEnabled: null,
    statuses: null,
})

const mutations = {
    setLoaded(state, value) {
        state.loaded = value
    },
    updateFilters(state, data) {
        state.countries = data.countries;
        state.metas = data.metas;
        state.landings = data.landings;
        state.sources = data.sources;
        state.tiers = data.tiers;
        state.filtersEnabled = data.filtersEnabled;
        state.statuses = data.statuses;
    },
}

const actions = {
    loadOnce({ state, commit }) {
        if (state.loaded) {
            return
        }

        commit('setLoaded', true)

        api.post(`/user/filters`).then(response => {
            commit('updateFilters', response.data)
        })
    },
}



export default {
    namespaced: true,
    state,
    actions,
    mutations
}
