<template>
    <div>
        <div
            class="fake-link"
            @click="viewNote(note)"
            :key="note.id"
            v-for="{ note, ninjaUser } in notes"
        >
            <b-alert
                variant="warning"
                show
                class="d-flex align-items-center justify-content-between mb-0 rounded-0"
            >
                <div>
                    <ninja-avatar :ninjaUser="ninjaUser" />
                    {{ note.note }}
                </div>
                <div class="d-flex align-items-center">
                    <small class="text-secondary">
                        {{ note.createdRelative }}
                    </small>
                </div>
            </b-alert>
        </div>

        <b-modal centered v-model="modal" title="Note" hide-footer>
            <div v-if="viewNode" class="d-flex align-items-center">
                {{ viewNode.note }}
                <small class="text-secondary">
                    {{ viewNode.createdRelative }}
                </small>
                <b-dropdown
                    v-if="enableDelete"
                    right
                    size="sm"
                    variant="light"
                    class="ml-2"
                >
                    <b-dropdown-item
                        variant="danger"
                        @click="deleteNote(viewNode)"
                    >
                        <font-awesome-icon icon="fa-light fa-times" />
                        Delete
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </b-modal>
    </div>
</template>


<script>
import { mapState } from "vuex";

import NinjaAvatar from "@/components/utility/NinjaAvatar";

export default {
    data() {
        return {
            viewNode: null,
            modal: false,
            notes: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        viewNote(note) {
            this.viewNode = note;
            this.modal = true;
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/notes`)
                .then((response) => {
                    this.notes = response?.data?.notes;
                });
        },
        deleteNote(note) {
            this.api.post(`/user/${note.id}/removeNote`).then(() => {
                this.$store.dispatch("userEdit/refresh");
                this.modal = false;
            });
        },
    },
    components: {
        NinjaAvatar,
    },
    props: ["enableDelete"],
};
</script>