<template>
    <b-alert
        variant="dark"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container>
            <b-alert v-if="buyerEscrows?.length" variant="success" show>
                <h5>Credits in escrow to be released upon marking legit</h5>
                <b-table-simple striped bordered>
                    <thead>
                        <th>Description</th>
                        <th>Amount</th>
                    </thead>
                    <b-tbody>
                        <b-tr
                            :key="buyerEscrow.id"
                            v-for="buyerEscrow in buyerEscrows"
                        >
                            <td>
                                {{ buyerEscrow.description }}
                            </td>
                            <td>
                                {{ buyerEscrow.amount }}
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-alert>
            <b-alert v-if="risks?.length" variant="warning" show>
                <h5>Accept all risks to mark the buyer</h5>
                <b-table-simple striped bordered>
                    <thead>
                        <th>Accept</th>
                        <th>Risk Details</th>
                    </thead>
                    <b-tbody>
                        <b-tr :key="risk.id" v-for="risk in risks">
                            <td style="width: 1%" class="align-middle">
                                <b-btn
                                    variant="light"
                                    @click="acceptRisk(risk)"
                                >
                                    <font-awesome-icon
                                        v-if="accepted.includes(risk.id)"
                                        icon="fa-light fa-check"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        icon="fa-light fa-circle"
                                    />
                                </b-btn>
                            </td>
                            <td>
                                {{ risk.risk }}
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-alert>

            <div>
                <h1 class="mb-0">New Buyer</h1>
            </div>

            <div class="d-flex align-items-center justify-content-around mt-3">
                <b-btn
                    :disabled="pause || !hasAcceptedRisks"
                    variant="primary"
                    @click="legit"
                    size="lg"
                    class="px-5 mx-5"
                >
                    <font-awesome-icon icon="fa-solid fa-thumbs-up" />
                    Legit
                </b-btn>
                <b-btn
                    variant="danger"
                    @click="confirmFraud = true"
                    size="lg"
                    class="px-5 mx-5"
                >
                    <font-awesome-icon icon="fa-solid fa-thumbs-down" />
                    Fraud
                </b-btn>
            </div>
        </b-container>

        <b-alert variant="danger" show v-if="confirmFraud" class="mt-3">
            <h3>Are you ABSOLUTELY Sure?</h3>
            <h3>This will void all transactions, and revoke credits</h3>

            <b-btn
                :disabled="pause"
                variant="danger"
                @click="fraud"
                size="lg"
                class="px-5 my-3"
            >
                Confirm fraud
            </b-btn>
        </b-alert>

        <div class="mt-3 d-flex align-items-center justify-content-center">
            <div v-if="buyer?.pdl" class="mx-2">
                <b-btn @click="pdlModal = true" variant="info">
                    Peope Data Labs Log
                </b-btn>

                <b-modal
                    centered
                    v-model="pdlModal"
                    title="People Data Labs Log"
                    hide-footer
                >
                    <b-textarea
                        v-model="buyer.pdl"
                        rows="20"
                        size="sm"
                        readonly
                    ></b-textarea>
                </b-modal>
            </div>
            <div v-if="ipqs" class="mx-2">
                <b-btn @click="ipqsModal = true" variant="info">
                    IPQualityScore
                </b-btn>

                <b-modal
                    centered
                    v-model="ipqsModal"
                    title="People Data Labs Log"
                    hide-footer
                >
                    <b-textarea
                        v-model="ipqs"
                        rows="20"
                        size="sm"
                        readonly
                    ></b-textarea>
                </b-modal>
            </div>
        </div>

        <b-button
            v-b-toggle.newBuyerTraining
            variant="info"
            size="sm"
            class="mt-3"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Training
        </b-button>
        <b-collapse id="newBuyerTraining">
            <div class="text-center mt-3">
                Assume every user will potentially submit a chargeback, do you
                have sufficient information to track this person down if they
                do? Training: if the first transaction in series fails, and the
                rest succeed, thats a good sign. Likely their bank flagged it
                from dating MCC, they confirmed it, so already their bank took a
                precaution and they overrode it by SMS confirmation most of the
                time, meaning they're using their own card. Examine behavior,
                are they spending judiciously, trying to exchange contacts as
                quickly as possible? If so, they're probably spending their own
                money. If they're making it rain, consuming content without much
                interaction, and other things don't line up like ZIP code from
                wrong country, very dangerous sign, could be stolen card,
                especially if first transaction doesn't fail.
            </div>
        </b-collapse>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            confirmFraud: false,
            pdlModal: false,
            ipqsModal: false,
            pause: true,
            buyer: null,
            ipqs: null,
            risks: null,
            buyerEscrows: null,
            accepted: [],
        };
    },
    mounted() {
        setTimeout(() => {
            this.pause = false;
        }, 1000);

        this.refresh();
    },
    computed: {
        hasAcceptedRisks() {
            return !this.risks?.filter(
                (risk) => !this.accepted.includes(risk.id)
            ).length;
        },
        response() {
            if (this.buyer?.peopleDataLabsLog?.response) {
                return JSON.stringify(
                    this.buyer.peopleDataLabsLog.response,
                    null,
                    2
                );
            }

            return "";
        },
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        acceptRisk(risk) {
            this.accepted.push(risk.id);
        },
        refresh() {
            this.api
                .post(`/buyer/${this.user.model.id}/get`)
                .then((response) => {
                    this.buyer = response.data.buyer;
                    this.risks = response.data.risks;
                    this.buyerEscrows = response.data.buyerEscrows;
                    this.ipqs = response.data.ipqs;
                });
        },
        async legit() {
            await this.api.post(`/buyer/${this.user.model.id}/legit`);
            this.$router.push("/buyers");
        },
        async fraud() {
            await this.api.post(`/buyer/${this.user.model.id}/fraud`);
            this.$router.push("/buyers");
        },
    },
};
</script>