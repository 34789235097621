import api from "@/service/api";

const state = () => ({

})

const mutations = {

}

const actions = {
    resetEmailMessage() {
        api.post('/throttle/resetEmailMessage')
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
