<template>
    <div>
        <b-btn
            @click="modal = true"
            variant="light"
            size="sm"
            class="border ml-2"
        >
            <coin-badge :amount="user.balance" />
        </b-btn>

        <b-modal centered v-model="modal" title="Wallet" hide-footer>
            <user-wallet />
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserWallet from "@/components/user/UserWallet";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    components: {
        UserWallet,
    },
};
</script>