<template>
    <div>
        <b-badge
            v-if="user.flags.vpnDetected"
            variant="dark"
            class="mr-2 text-warning border border-warning"
        >
            <font-awesome-icon class="text-warning" icon="fa-duotone fa-wifi" />
            VPN
        </b-badge>
        <b-badge v-if="user.flags.isGoogleAds" variant="dark" class="mr-2">
            <font-awesome-icon
                class="text-primary"
                icon="fa-brands fa-google"
            />
            Google Ads
        </b-badge>
        <b-badge v-if="ios" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-brands fa-apple" />
            iOS
        </b-badge>
        <b-badge v-if="android" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-brands fa-android" />
            Android
        </b-badge>
        <b-badge v-if="user.flags.lgbt" variant="dark" class="mr-2">
            <small>🌈</small>
            <span class=""> LGBTQ </span>
        </b-badge>
        <b-badge v-if="user.flags.hasGps" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-duotone fa-location-check" />
            GPS
        </b-badge>
        <b-badge v-if="user.model.private" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-duotone fa-lock" />
            Private
        </b-badge>
        <b-badge v-if="user.model.hidden" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-duotone fa-eye-slash" />
            Hidden
        </b-badge>
        <b-badge v-if="user.model.whitelisted" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-solid fa-network-wired" />
            Whitelisted
        </b-badge>
        <b-badge v-if="user.model.vip" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-solid fa-star" class="text-warning" />
            VIP
        </b-badge>
        <b-badge v-if="user.model.expat" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-duotone fa-plane" />
            Expat
        </b-badge>
        <b-badge v-if="user.model.vetted" variant="dark" class="mr-2">
            <font-awesome-icon
                icon="fa-solid fa-shield-check"
                class="text-success"
            />
            Vetted
        </b-badge>
        <b-badge v-if="user.model.censored" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-duotone fa-eye-slash" />
            Censored
        </b-badge>
        <b-badge v-if="user.model.sugarbaby" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-solid fa-candy" class="branded" />
            Sugarbaby
        </b-badge>
        <b-badge v-if="user.model.affiliate" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-duotone fa-person-circle-plus" />
            Affiliate
        </b-badge>
        <b-badge
            v-if="user.model.appearance === 'hot'"
            variant="dark"
            class="mr-2"
        >
            <font-awesome-icon icon="fa-solid fa-flame" class="text-danger" />
            Hot
        </b-badge>
        <b-badge
            v-if="user.model.appearance === 'cute'"
            variant="dark"
            class="mr-2"
        >
            <font-awesome-icon
                icon="fa-duotone fa-flame"
                class="text-warning"
            />
            Cute
        </b-badge>
        <b-badge
            v-if="user.model.appearance === 'ugly'"
            variant="dark"
            class="mr-2"
        >
            <font-awesome-icon icon="fa-duotone fa-face-zany" />
            Ugly
        </b-badge>
        <b-badge
            v-if="user.model.ghost"
            variant="light"
            class="mr-2 text-warning"
        >
            <font-awesome-icon icon="fa-duotone fa-ghost" />
            Ghost
        </b-badge>
        <b-badge v-if="user.model.criedWolf" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-brands fa-wolf-pack-battalion" />
            Cried Wolf
        </b-badge>
        <b-badge v-if="user.model.creator" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-duotone fa-clapperboard-play" />
            Creator
        </b-badge>
        <b-badge
            v-if="user.flags.hasLegacyBillingProfiles"
            variant="dark"
            class="mr-2 text-secondary"
        >
            <font-awesome-icon icon="fa-duotone fa-credit-card" />
            Stripe
        </b-badge>
        <b-badge v-if="user.model.test" variant="info" class="mr-2">
            Test
        </b-badge>
        <b-badge v-if="user.model.disregard" variant="dark" class="mr-2">
            <font-awesome-icon icon="fa-solid fa-ban" class="text-danger" />
            Disregard
        </b-badge>
    </div>
</template>


<script>
export default {
    computed: {
        ios() {
            return this.user.devices?.find(
                (device) => device.platform === "ios"
            );
        },
        android() {
            return this.user.devices?.find(
                (device) => device.platform === "android"
            );
        },
    },
    props: ["user"],
};
</script>