<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>Fine</th>
                    <th>Amount</th>
                    <th>Paid</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="userFine.id" v-for="userFine in userFines">
                    <td>{{ userFine.reasonObject.title }}</td>
                    <td>
                        <coin-badge :amount="userFine.amount" />
                    </td>
                    <td>
                        <div v-if="userFine.paid">
                            <font-awesome-icon
                                icon="fa-light fa-check"
                                class="text-success"
                            />
                        </div>
                        <div v-else>
                            <b-btn
                                @click="deleteFine(userFine)"
                                variant="light"
                                class="text-danger"
                            >
                                Delete
                            </b-btn>
                        </div>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            userFines: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        deleteFine(userFine) {
            this.api.post(`/fine/${userFine.id}/delete`).then(() => {
                this.refresh();
            });
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/fines`)
                .then((response) => {
                    this.userFines = response.data.userFines;
                });
        },
    },
};
</script>