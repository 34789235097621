<template>
    <div>
        <b-table-simple class="small">
            <b-thead>
                <tr>
                    <th></th>
                    <th style="width: 45%">Old Values</th>
                    <th style="width: 45%">New Values</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="audit.id" v-for="audit in audits">
                    <td>{{ audit.createdRelative }}</td>
                    <td class="bg-light">
                        <pre class="audit mb-0 small text-">{{
                            audit.oldValues
                        }}</pre>
                    </td>
                    <td>
                        <pre class="audit mb-0 small text-">{{
                            audit.newValues
                        }}</pre>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            audits: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/audits`)
                .then((response) => {
                    this.audits = response.data.audits;
                });
        },
    },
};
</script>