<template>
    <div class="">
        <b-link @click="photosTab">
            <div v-if="user.primaryPhoto" class="position-relative">
                <b-img :src="user.primaryPhoto.medium" fluid />

                <div
                    v-if="user.primaryVideo"
                    class="position-absolute"
                    style="width: 100px; bottom: 0.5rem; left: 0.5rem"
                >
                    <video
                        playsinline
                        autoplay
                        muted
                        loop
                        controls="false"
                        class="img-fluid rounded border m-0 p-0"
                        :src="user.primaryVideo.mp4Url"
                    ></video>
                </div>
            </div>

            <b-row no-gutters>
                <b-col cols="2" :key="photo.id" v-for="photo in limitedPhotos">
                    <div class="position-relative">
                        <b-img :src="photo.medium" fluid />
                    </div>
                </b-col>
            </b-row>
        </b-link>

        <div
            class="d-flex align-items-center justify-content-between p-2 small border-top"
        >
            <div class="text-white text-truncate">
                <img :src="user.flagUrl" />
                <b-link
                    class="text-white text-decoration-none"
                    :to="`/user/edit/${user.model.id}`"
                >
                    {{ user.idealName }}
                </b-link>
                <user-status :user="user" />
            </div>
            <div class="text-white">
                <user-orientation :user="user" />
            </div>
        </div>

        <div class="p-2 border-top">
            <user-flags :user="user" />
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import UserOrientation from "@/components/utility/UserOrientation";
import UserStatus from "@/components/utility/UserStatus";
import UserFlags from "@/components/user/UserFlags";

export default {
    methods: {
        photosTab() {
            this.$store.dispatch("userEdit/changeTab", {
                tab: "photos",
                route: this.$route,
            });
        },
    },
    computed: {
        limitedPhotos() {
            return this.user.photos?.slice(0, 12);
        },
        ...mapState("userEdit", ["user"]),
    },
    components: {
        UserOrientation,
        UserStatus,
        UserFlags,
    },
};
</script>