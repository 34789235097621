<template>
    <generic-page>
        <b-container>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1>Keywords</h1>
                </div>
                <div>
                    <b-btn variant="primary" to="/keyword/create" class="mr-2">
                        Create
                    </b-btn>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="filter">
                                ({{ filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('monitor')">
                            Monitor
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('ignore')">
                            Ignore
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <b-table-simple striped>
                    <b-thead>
                        <th></th>
                        <th>Keyword</th>
                        <th>Action</th>
                        <th>Prompt</th>
                        <th>Created</th>
                    </b-thead>
                    <b-tbody>
                        <b-tr :key="keyword.id" v-for="keyword in keywords">
                            <td style="width: 1%" class="align-middle">
                                <b-btn
                                    variant="light"
                                    :to="`/keyword/edit/${keyword.id}`"
                                >
                                    <font-awesome-icon
                                        icon="fa-light fa-pencil"
                                    />
                                </b-btn>
                            </td>
                            <td>
                                {{ keyword.keyword }}
                            </td>
                            <td>
                                {{ keyword.action }}
                            </td>
                            <td>
                                <font-awesome-icon
                                    v-if="keyword.aiPrompt"
                                    icon="fa-light fa-check"
                                />
                            </td>
                            <td>
                                {{ keyword.createdRelative }}
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            loading: false,
            filter: null,
            keywords: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;

            this.api
                .post("/keyword/index", {
                    filter: this.filter,
                })
                .then((response) => {
                    this.keywords = response.data.keywords;
                    this.loading = false;
                });
        },
    },
    components: {},
};
</script>