<template>
    <span class="fake-link" v-b-tooltip.click :title="timestamp">
        {{ $date(timestamp).from($date(this.now)) }}
    </span>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["timestamp"],
    computed: {
        ...mapState("system", ["now"]),
    },
};
</script>
