<template>
    <div>
        <h3>Logs</h3>

        <b-table-simple class="small" striped>
            <b-tbody>
                <b-tr :key="log.id" v-for="log in logs">
                    <b-th class="bg-white text-truncate" style="width: 1%">
                        {{ log.createdRelative }}
                    </b-th>
                    <b-td>
                        <span
                            class="circle-item"
                            v-if="log?.user?.firstLetterOfName"
                        >
                            {{ log.user.firstLetterOfName }}
                        </span>
                        {{ log.log }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw", "user", "invitedByUser", "logs"]),
    },
};
</script>