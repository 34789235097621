<template>
    <div>
        <b-row>
            <b-col cols="6" class="text-center">
                <b-form-input size="lg" v-model="addAmount"></b-form-input>
                <b-btn
                    @click="addCredits"
                    size="lg"
                    class="mt-2"
                    block
                    variant="primary"
                >
                    Add
                </b-btn>
            </b-col>
            <b-col cols="6" class="text-center">
                <b-form-input size="lg" v-model="removeAmount"></b-form-input>
                <b-btn
                    @click="removeCredits"
                    size="lg"
                    class="mt-2"
                    block
                    variant="danger"
                >
                    Remove
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            addAmount: null,
            removeAmount: null,
        };
    },
    mounted() {
        this.removeAmount = this.user.balance;
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async addCredits() {
            await this.api.post(`/credit/${this.user.model.id}/add`, {
                amount: this.addAmount,
            });

            this.$store.dispatch("userEdit/refresh");
        },
        async removeCredits() {
            await this.api.post(`/credit/${this.user.model.id}/remove`, {
                amount: this.removeAmount,
            });

            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>