<template>
    <div class="p-3">
        <b-dropdown variant="dark" class="mr-3">
            <template #button-content>
                Status
                <span class="text-info" v-if="status"> ({{ status }}) </span>
            </template>
            <b-dropdown-item
                @click="filterStatus(status)"
                :key="status"
                v-for="status in statuses"
            >
                {{ status }}
            </b-dropdown-item>
        </b-dropdown>
        <b-dropdown variant="dark">
            <template #button-content>
                Country
                <span class="text-info" v-if="country">
                    ({{ country.name }})
                </span>
            </template>
            <b-dropdown-item
                @click="filterCountry(country)"
                :key="country.id"
                v-for="country in countries"
            >
                <img :src="country.flagUrl" />

                {{ country.name }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            country: null,
            status: null,
        };
    },
    mounted() {
        this.$store.dispatch("userFilters/loadOnce");
    },
    methods: {
        filterStatus(status) {
            this.status = status;

            this.pushFilter({
                status,
            });
        },
        filterCountry(country) {
            this.country = country;

            this.pushFilter({
                countryId: country.id,
            });
        },
        pushFilter(filter) {
            const query = {
                ...this.$route.query,
                ...filter,
            };

            console.log(query);

            this.$emit("filtersUpdated", query);
        },
    },
    computed: {
        ...mapState("userFilters", [
            "loaded",
            "countries",
            "metas",
            "landings",
            "sources",
            "tiers",
            "filtersEnabled",
            "statuses",
        ]),
    },
};
</script>