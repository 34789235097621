<template>
    <b-tr>
        <b-th class="bg-light" style="width: 40%"> Notifications </b-th>
        <b-td class="py-0 align-middle">
            <b-dropdown
                right
                :text="user.notifications"
                variant="light"
                size="sm"
                class="border w-100 text-capitalize"
            >
                <b-dropdown-item
                    :key="option"
                    v-for="option in options"
                    @click="setOption(option)"
                    class="text-capitalize"
                >
                    {{ option }}
                </b-dropdown-item>
            </b-dropdown>
        </b-td>
    </b-tr>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            options: ["off", "follow", "all"],
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async setOption(option) {
            await this.api.post(`/user/${this.user.model.id}/notifications`, {
                notifications: option,
            });

            this.$store.dispatch("userEdit/refresh");
        },
        async resetLastActive() {
            await this.api.post(`/user/${this.user.model.id}/resetLastActive`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>