<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>Keyword</th>
                    <th>Action</th>
                    <th>Harmless</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    :key="userKeyword.userKeyword.id"
                    v-for="userKeyword in userKeywords"
                >
                    <td>{{ userKeyword.keyword.keyword }}</td>
                    <td>{{ userKeyword.keyword.action }}</td>
                    <td>{{ userKeyword.userKeyword.harmless }}</td>
                    <td>{{ userKeyword.userKeyword.createdRelative }}</td>
                    <td>
                        <b-link
                            :to="`/keyword/instance/${userKeyword.userKeyword.id}`"
                            :key="userKeyword.userKeyword.id"
                        >
                            View
                        </b-link>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            userKeywords: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/keywords`)
                .then((response) => {
                    this.userKeywords = response.data.userKeywords;
                });
        },
    },
};
</script>