<template>
    <div>
        <b-link
            :to="`/user/edit/${user.model.id}`"
            style="heigth: 80px; width: 80px"
            class="text-decoration-none text-light small d-block position-relative overflow-hidden rounded"
        >
            <div class="position-relative">
                <div v-if="user.primaryPhoto">
                    <img height="80" :src="user.primaryPhoto.search" />
                </div>
                <div v-else>
                    <div style="height: 80px" class="bg-secondary"></div>
                </div>

                <div class="position-absolute" style="top: 0">
                    <img class="align-top" :src="user.flagUrl" />
                </div>
            </div>
            <div
                style="
                    opacity: 0.7;
                    font-size: 75%;
                    z-index: 1;
                    bottom: 0;
                    background-color: #000;
                "
                class="d-flex align-items-center justify-content-between px-1 position-absolute w-100"
            >
                <div class="text-white text-truncate">
                    <font-awesome-icon
                        v-if="user.invitesRequired"
                        class="text-warning"
                        style="margin-right: 1px"
                        icon="fa-duotone fa-lock"
                    />
                    <user-status :user="user" />

                    {{ user.idealName }}
                </div>
                <div class="text-white">
                    <user-orientation :user="user" />
                </div>
            </div>
        </b-link>
    </div>
</template>

<script>
import UserOrientation from "@/components/utility/UserOrientation";
import UserStatus from "@/components/utility/UserStatus";

export default {
    components: {
        UserStatus,
        UserOrientation,
    },
    props: ["user"],
};
</script>