<template>
    <div>
        <user-photos />
    </div>
</template>

<script>
import UserPhotos from "@/components/user/UserPhotos";

export default {
    components: {
        UserPhotos,
    },
};
</script>