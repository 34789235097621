<template>
    <public-page>
        <img
            class="img-fluid my-3"
            src="https://findmate-assets.s3.amazonaws.com/ninja/ninja.webp"
        />

        <b-form @submit.stop.prevent="loginEmail">
            <b-alert v-if="error" show variant="danger">
                {{ error }}
            </b-alert>
            <b-form-group label="Email">
                <b-form-input
                    size="lg"
                    v-model="email"
                    @input="edited = true"
                    type="email"
                    autocorrect="off"
                    autocomplete="email"
                    autocapitalize="none"
                    :state="error ? false : null"
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Password">
                <b-form-input
                    size="lg"
                    v-model="password"
                    type="password"
                    :state="error ? false : null"
                ></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-btn
                    @click="loginEmail"
                    variant="light"
                    size="lg"
                    block
                    class="text-danger"
                >
                    Login
                </b-btn>
            </b-form-group>
        </b-form>
    </public-page>
</template>

<script>
export default {
    data() {
        return {
            error: null,
            email: null,
            password: null,
        };
    },
    methods: {
        loginEmail() {
            this.api
                .post("/login/email", {
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    // password is the token for now
                    this.$store.dispatch("user/login", this.password);
                })
                .catch(() => {
                    this.error = "Invalid email/password";
                });
        },
    },
};
</script>