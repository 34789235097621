<template>
    <b-badge pill variant="light">
        <span v-if="label" class="mr-2">{{ label }}</span>
        <font-awesome-icon
            icon="fa-duotone fa-coin"
            class="text-warning"
            size="lg"
        />
        {{ amount }}
    </b-badge>
</template>

<script>
export default {
    props: ["amount", "label"],
};
</script>