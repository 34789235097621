<template>
    <div>
        <b-table-simple striped>
            <b-thead>
                <b-th> Created </b-th>
                <b-th> Endpoint </b-th>
                <b-th> Request </b-th>
                <b-th> Response </b-th>
            </b-thead>
            <b-tbody>
                <b-tr :key="wiseLog.id" v-for="wiseLog in wiseLogs">
                    <td>
                        <live-timestamp :timestamp="wiseLog.created" />
                    </td>
                    <td>
                        {{ wiseLog.endpoint }}
                    </td>
                    <td>
                        {{ wiseLog.request }}
                    </td>
                    <td>
                        {{ wiseLog.response }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            wiseLogs: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/wiseLogs`)
                .then((response) => {
                    this.wiseLogs = response.data.wiseLogs;
                });
        },
    },
    components: {},
};
</script>