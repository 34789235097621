<template>
    <b-alert
        variant="info"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container>
            <div v-if="user.flags.blockCreditPurchase">
                <h3>Credit Purchase Blocked</h3>
                <b-btn @click="removeBlockCreditPurchase">
                    Allow Credit Purchases
                </b-btn>
            </div>

            <div class="d-flex align-items-center justify-content-around mt-3">
                <div>
                    <h1 class="mb-0">ID Verification</h1>
                </div>

                <div>
                    Born before
                    <h3 class="text-dark">
                        {{ eighteenYearsAgo }}
                    </h3>
                </div>
            </div>

            <div class="d-flex justify-content-around my-5">
                <b-btn
                    :disabled="saving"
                    variant="danger"
                    @click="reject"
                    size="lg"
                    class="px-5"
                >
                    Reject
                </b-btn>
                <b-btn
                    :disabled="saving"
                    variant="primary"
                    @click="approve"
                    size="lg"
                    class="px-5"
                >
                    Approve
                </b-btn>
            </div>

            <div
                :key="userDocument.id"
                v-for="userDocument in userDocuments"
                class="border bg-light p-3 my-3"
            >
                <h3 class="text-center p-3">
                    {{ userDocument.type }}
                </h3>
                <b-img fluid :src="userDocument.signed" />
                <div class="mt-3">
                    <b-row class="w-50 mx-auto">
                        <b-col cols="6">
                            <div>Date of Birth</div>
                            {{ user.dateOfBirthFormatted }}
                        </b-col>
                        <b-col cols="6">
                            <div>Age</div>
                            {{ user.age }}
                        </b-col>
                    </b-row>
                </div>
            </div>

            <b-alert variant="danger" show>
                <div>
                    If the user does not cooperate and we can't let them in
                    without ID, mark them ineligible to remove them from the
                    queue
                </div>

                <div class="mt-3">
                    <b-button v-b-toggle.noId variant="danger">
                        User is not providing ID
                    </b-button>
                    <b-collapse id="noId">
                        <b-btn
                            :disabled="saving"
                            variant="danger"
                            @click="ineligible"
                            size="lg"
                            class="px-5 mt-4"
                        >
                            Ineligible - will ignore further documents,
                            rendering the account dead
                        </b-btn>
                    </b-collapse>
                </div>
            </b-alert>
        </b-container>

        <b-button
            v-b-toggle.newBuyerTraining
            variant="info"
            size="sm"
            class="mt-3"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Training
        </b-button>
        <b-collapse id="newBuyerTraining">
            <div class="text-center mt-3">
                Ensure the documents are legitimate. Expect to see some fakes,
                some users may be able to use AI or other modern tools to
                generate very realistic looking fakes. Try to find an ID guide
                online for the specific ID type online
            </div>
        </b-collapse>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            saving: false,
            userDocuments: null,
            eighteenYearsAgo: null,
        };
    },
    mounted() {
        this.refresh();

        const formatDate = (date) => {
            const options = { year: "numeric", month: "short", day: "numeric" };
            return date.toLocaleDateString("en-US", options);
        };

        const today = new Date();
        const date18YearsAgo = new Date();
        date18YearsAgo.setFullYear(today.getFullYear() - 18);
        this.eighteenYearsAgo = formatDate(date18YearsAgo); // Format as YYYY-MM-DD
    },
    computed: {
        userDocumentIds() {
            return this.userDocuments.map((userDocument) => userDocument.id);
        },
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async ineligible() {
            this.saving = true;

            this.api
                .post(`/document/${this.user.model.id}/ineligible`)
                .then(() => {
                    this.refresh();
                });
        },
        approve() {
            this.saving = true;

            this.api
                .post(`/id/${this.user.model.id}/approve`, {
                    userDocumentIds: this.userDocumentIds,
                })
                .then(() => {
                    this.refresh();
                });
        },
        reject() {
            this.saving = true;

            this.api
                .post(`/id/${this.user.model.id}/reject`, {
                    userDocumentIds: this.userDocumentIds,
                })
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.api
                .post(`/id/${this.user.model.id}/index`)
                .then((response) => {
                    this.saving = false;
                    this.userDocuments = response.data.userDocuments;

                    if (!this.userDocuments.length) {
                        this.$router.push("/id");
                    }
                });
        },
    },
};
</script>