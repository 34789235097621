const state = () => ({
    method: null,
})

const mutations = {
    setMethod(state, method) {
        state.method = method
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}



