<template>
    <div>
        <user-videos />
    </div>
</template>

<script>
import UserVideos from "@/components/user/UserVideos";

export default {
    components: {
        UserVideos,
    },
};
</script>