<template>
    <generic-page>
        <b-container>
            <div class="mt-3 d-flex align-items-center justify-content-between">
                <h1 class="mb-0">Search</h1>
                <div v-if="users" class="lead text-secondary">
                    {{ users.length }} results
                </div>
            </div>

            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <b-list-group>
                    <b-list-group-item
                        action
                        :to="`/user/edit/${user.model.id}`"
                        :key="user.id"
                        v-for="user in users"
                        class="d-flex align-items-center"
                    >
                        <user-avatar :user="user" />
                        <div class="ml-3">
                            <h5>{{ user.model.name }}</h5>
                            <div>
                                Active
                                <live-timestamp
                                    :timestamp="user.model.lastActive"
                                />
                            </div>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-container>
    </generic-page>
</template>
<script>
export default {
    data() {
        return {
            loading: null,
            users: null,
        };
    },
    mounted() {
        this.refresh();
    },
    watch: {
        $route() {
            this.refresh();
        },
    },
    methods: {
        refresh() {
            this.loading = true;
            this.users = null;

            this.api
                .post("/search/keyword", {
                    keyword: this.$route.query?.keyword,
                })
                .then((response) => {
                    if (response.data.foundUserId) {
                        this.$router.push(
                            `/user/edit/${response.data.foundUserId}`
                        );
                    } else {
                        this.users = response.data.users;
                    }

                    this.loading = false;
                });
        },
    },
    metaInfo() {
        return {
            title: "Search",
        };
    },
};
</script>