<template>
    <div>
        <b-row>
            <b-col cols="6">
                <h5>Latest Sent Messages</h5>
                <b-table-simple class="small" striped>
                    <b-thead>
                        <tr>
                            <th>Message</th>
                            <th>Count</th>
                            <th>Created</th>
                        </tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr :key="message.id" v-for="message in messages">
                            <td>
                                <user-avatar :user="message.user" />
                            </td>
                            <td>
                                <div>
                                    <b-badge
                                        v-if="message.deletedFrom"
                                        variant="light"
                                        class="text-danger"
                                    >
                                        Deleted From
                                    </b-badge>
                                    <b-badge
                                        v-if="message.deletedTo"
                                        variant="light"
                                        class="text-danger"
                                    >
                                        Deleted To
                                    </b-badge>
                                </div>

                                {{ message.contentRendered }}
                            </td>
                            <td>
                                {{ message.createdRelative }}
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col cols="6">
                <h5>Messages Grouped by Count</h5>
                <b-table-simple class="small" striped>
                    <b-thead>
                        <tr>
                            <th>Message</th>
                            <th>Count</th>
                        </tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr
                            :key="messageByCount.key"
                            v-for="messageByCount in messagesByCount"
                        >
                            <td>
                                {{ messageByCount.message }}
                            </td>
                            <td>
                                {{ messageByCount.count }}
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            messagesByCount: null,
            messages: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
        ...mapState("user", ["trainingWheels"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/messagesByCount`)
                .then((response) => {
                    this.messagesByCount = response.data.messagesByCount;
                });

            this.api
                .post(`/user/${this.user.model.id}/messages`)
                .then((response) => {
                    this.messages = response.data.messages;
                });
        },
    },
};
</script>