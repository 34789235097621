<template>
    <div class="border rounded p-3 my-3">
        <div class="d-flex align-items-center justify-content-between">
            <h3>Wise</h3>
            <b-dropdown variant="light" text="More Options" right>
                <b-dropdown-item
                    v-if="withdraw.transferCancellable"
                    @click="cancelTransfer"
                >
                    Cancel Transfer
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <b-table-simple class="userFields small" borderless>
            <b-tbody>
                <b-tr v-if="withdraw.wiseTransferId">
                    <b-th class="bg-light"> Wise Transfer ID </b-th>
                    <b-td class="text-truncate">
                        {{ withdraw.wiseTransferId }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> Status </b-th>
                    <b-td class="text-truncate">
                        {{ withdraw.transferStatus }}
                    </b-td>
                </b-tr>
                <b-tr v-if="withdraw.wiseUrl">
                    <b-th class="bg-light"> Wise Link </b-th>
                    <b-td class="text-truncate">
                        <b-btn
                            target="_blank"
                            :href="withdraw.wiseUrl"
                            variant="success"
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-arrow-up-right-from-square"
                            />
                            View on Wise
                        </b-btn>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <div class="mt-3">
            <div v-if="withdraw.funded">Funded, transfer complete</div>
            <div v-else>
                <b-btn
                    :disabled="funding"
                    variant="success"
                    size="lg"
                    block
                    @click="fund"
                >
                    Fund Transfer
                </b-btn>
            </div>
        </div>

        <b-alert class="mt-3" v-if="withdraw.bouncedBack" variant="danger" show>
            <h1>Bounced Back</h1>
            The transfer was bounced back by their bank, easiest option is to
            cancel and refund so they can try again
            <b-btn
                class="mt-3"
                block
                variant="danger"
                size="lg"
                v-if="withdraw.transferCancellable"
                @click="cancelTransfer"
            >
                Cancel Transfer
            </b-btn>
        </b-alert>

        <b-alert class="mt-3" variant="warning" v-if="withdraw.issue" show>
            <h3>Transfer Issue</h3>
            <div>
                If user is in Japan, they require an extra manual step, select
                option "Paying for Services" that seems to work OK so far..
            </div>
            <div
                class="mt-3 d-flex align-items-center justify-content-between"
                v-if="withdraw.wiseUrl"
            >
                <b-btn
                    target="_blank"
                    :href="withdraw.wiseUrl"
                    variant="success"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-arrow-up-right-from-square"
                    />
                    View Transfer on Wise
                </b-btn>

                <b-dropdown text="Actions" right>
                    <b-dropdown-item @click="removeIssue">
                        Mark issue resolved on our end
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            funding: false,
        };
    },
    methods: {
        async removeIssue() {
            await this.api.post(`/withdraw/${this.withdraw.id}/removeIssue`);
            this.$store.dispatch("withdraw/refresh");
        },
        async fund() {
            this.funding = true;

            await this.api.post(`/withdraw/${this.withdraw.id}/fund`);
            this.$store.dispatch("withdraw/refresh");

            this.funding = false;
        },
        async cancelTransfer() {
            await this.api.post(`/withdraw/${this.withdraw.id}/cancelTransfer`);
            this.$store.dispatch("withdraw/refresh");
        },
    },
    computed: {
        ...mapState("withdraw", ["withdraw", "user"]),
    },
    components: {},
};
</script>