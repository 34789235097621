<template>
    <b-container>
        <div v-if="existingTabOpen">
            <b-alert variant="warning" show class="mt-3">
                <h1>Dashboard already open</h1>
                Dashboard already open on another tab, to prevent overloading
                the server, it can only be opened on one tab at a time. If you
                close the other tab and refresh it will load, or navigate back
                to the already open tab.
            </b-alert>

            <div class="mt-3">
                <b-btn
                    @click="clearTab"
                    variant="success"
                    size="lg"
                    block
                    class="p-5"
                >
                    <h1 class="mb-0">Clear Lock &amp; Refresh</h1>
                </b-btn>
            </div>
        </div>
        <div v-else>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <h1 class="mb-0">Dashboard</h1>
                <div>
                    <div v-if="loading">
                        <b-spinner small variant="dark" />
                    </div>
                    <div v-else>
                        <b-btn
                            v-if="isRoot && wise"
                            :variant="wise.variant"
                            size="sm"
                        >
                            Wise ${{ wise.balance }}
                        </b-btn>
                    </div>
                </div>
            </div>
            <b-row>
                <b-col
                    cols="12"
                    md="4"
                    :key="tile.url"
                    v-for="tile in tiles"
                    class="my-3"
                >
                    <b-btn
                        v-if="tile.url"
                        :to="tile.url"
                        block
                        variant="light"
                        class="border rounded h-100 p-4 py-5 text-center"
                        :class="tile.class"
                    >
                        <h1 class="display-3 text-dark">
                            {{ tile.count }}
                        </h1>
                        <h4 class="mb-0">
                            {{ tile.title }}
                        </h4>
                    </b-btn>
                    <b-btn
                        v-else-if="tile.legacyUrl"
                        :href="tile.legacyUrl"
                        target="_blank"
                        block
                        variant="light"
                        class="border rounded h-100 p-4 py-5 text-center"
                        :class="tile.class"
                    >
                        <h1 class="display-3 text-dark">
                            {{ tile.count }}
                        </h1>
                        <h4 class="mb-0">
                            {{ tile.title }}
                        </h4>
                        <div class="text-dark">Old System</div>
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            interval: null,
            tabId: null,
            existingTabOpen: false,
        };
    },
    mounted() {
        this.initialize();
    },
    computed: {
        ...mapState("dashboard", ["tiles", "wise", "loading"]),
        ...mapState("user", ["isRoot"]),
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        window.removeEventListener("beforeunload", this.handleBeforeUnload);

        this.removeTabId();
    },
    methods: {
        initialize() {
            this.tabId = Date.now().toString();

            const existingTabId = localStorage.getItem("openTabId");

            if (existingTabId) {
                this.existingTabOpen = true;
            } else {
                localStorage.setItem("openTabId", this.tabId);

                this.interval = setInterval(() => {
                    this.$store.dispatch("dashboard/refresh");
                }, 180000);

                this.$store.dispatch("dashboard/refresh");
            }

            window.addEventListener("beforeunload", this.handleBeforeUnload);
        },
        clearTab() {
            this.existingTabOpen = false;
            localStorage.removeItem("openTabId");
            this.initialize();
        },
        handleBeforeUnload() {
            this.removeTabId();
        },
        removeTabId() {
            const currentTabId = localStorage.getItem("openTabId");

            if (currentTabId === this.tabId) {
                localStorage.removeItem("openTabId");
            }
        },
    },
    metaInfo() {
        return {
            title: "Dashboard",
        };
    },
};
</script>