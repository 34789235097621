<template>
    <div>
        <b-btn variant="danger" @click="rejectAll" size="lg" class="px-5">
            Reject All (actually soft deletes)
        </b-btn>

        <b-table-simple class="small mt-3" striped>
            <b-thead>
                <tr>
                    <th>Document</th>
                    <th>Type</th>
                    <th>Approved</th>
                    <th>Created</th>
                    <th>Deleted</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="document.id" v-for="document in documents">
                    <td>
                        <img class="img-fluid" :src="document.signed" />
                    </td>
                    <td>{{ document.type }}</td>
                    <td>{{ document.approved }}</td>
                    <td>{{ document.createdRelative }}</td>
                    <td>
                        <font-awesome-icon
                            v-if="document.deleted"
                            icon="fa-light fa-check"
                        />
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            documents: null,
        };
    },
    computed: {
        userDocumentIds() {
            return this.documents.map((document) => document.id);
        },
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        rejectAll() {
            this.api
                .post(`/document/${this.user.model.id}/reject`, {
                    userDocumentIds: this.userDocumentIds,
                    silent: true,
                })
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/documents`)
                .then((response) => {
                    this.documents = response.data.documents;
                });
        },
    },
};
</script>