<template>
    <generic-page>
        <b-container>
            <h1 class="mt-3">Create Keyword</h1>

            Keyword
            <b-form-input size="lg" v-model="keyword"></b-form-input>

            <b-btn
                @click="create"
                size="lg"
                class="mt-2"
                block
                variant="primary"
            >
                Create
            </b-btn>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            keyword: null,
        };
    },
    methods: {
        async create() {
            this.api
                .post(`/keyword/create`, {
                    keyword: this.keyword,
                })
                .then((response) => {
                    if (response?.data?.keyword) {
                        this.$router.push(
                            `/keyword/edit/${response.data.keyword.id}`
                        );
                    }
                });
        },
    },
};
</script>