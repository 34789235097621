<template>
    <div>
        <div class="p-3">
            <b-btn @click="addHotspot">Add Hotspot</b-btn>
        </div>

        <generic-loading v-if="loading" />

        <user-hotspots />

        <b-list-group flush>
            <b-list-group-item
                button
                @click="$router.push(`/user/edit/${user.model.id}`)"
                :key="nearbyUsers.user.model.id"
                v-for="nearbyUsers in nearbyUsers"
                class="d-flex align-items-center"
            >
                <div class="mr-3">
                    <user-avatar :user="nearbyUsers.user" />
                </div>
                {{ nearbyUsers.distance }} KM
            </b-list-group-item>
        </b-list-group>
    </div>
</template>


<script>
import { mapState } from "vuex";

import UserHotspots from "@/components/user/UserHotspots";

export default {
    created() {
        this.refresh();
    },
    data() {
        return {
            loading: null,
            nearbyUsers: null,
            nearbyHotspots: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async addHotspot() {
            await this.api.post(`/gps/${this.user.model.id}/hotspot`);
            this.$store.dispatch("userEdit/refresh");
        },
        refresh() {
            this.loading = true;

            this.api
                .post(`/gps/${this.user.model.id}/nearby`)
                .then((response) => {
                    this.nearbyUsers = response.data.users;
                    this.loading = false;
                });

            this.api
                .post(`/user/${this.user.model.id}/nearbyHotspots`)
                .then((response) => {
                    this.nearbyHotspots = response.data.nearbyHotspots;
                });
        },
    },
    components: {
        UserHotspots,
    },
};
</script>