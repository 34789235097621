<template>
    <div class="bg-light p-3">
        <h1>IP Address</h1>

        <b-table-simple class="small" striped>
            <b-tbody>
                <b-tr>
                    <b-th> ID </b-th>
                    <b-td>
                        {{ ipAddress.id }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> IP Address </b-th>
                    <b-td>
                        {{ ipAddress.ipAddress }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Status </b-th>
                    <b-td>
                        {{ ipAddress.status }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> VPN </b-th>
                    <b-td>
                        {{ ipAddress.isVpn }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Banned </b-th>
                    <b-td>
                        {{ ipAddress.banned }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Ban Count </b-th>
                    <b-td>
                        {{ ipAddress.banCount }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Location </b-th>
                    <b-td>
                        <img :src="ipAddress.country?.flagUrl" />
                        {{ ipAddress.city?.name }},
                        {{ ipAddress.country?.name }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Created </b-th>
                    <b-td>
                        {{ ipAddress.createdRelative }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ipAddress: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/ipAddress/${this.$route.query.ipAddressId}/get`)
                .then((response) => {
                    this.ipAddress = response?.data?.ipAddress;
                });
        },
    },
};
</script>