<template>
    <generic-page>
        <b-container>
            <div v-if="blacklist" class="mt-3">
                <div
                    class="d-flex align-items-center justify-content-between mt-3"
                >
                    <h1>
                        Blacklist
                        {{ blacklist.id }}
                    </h1>

                    <div>
                        <b-dropdown variant="light" right>
                            <template #button-content> Actions </template>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item
                                variant="danger"
                                @click="deleteBlacklist"
                            >
                                <font-awesome-icon icon="fa-light fa-times" />
                                Delete
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

                <b-alert variant="primary" show class="small">
                    When a transaction matches all of the criteria below, it
                    will be blocked
                </b-alert>
                <b-alert variant="danger" show class="small" v-if="error">
                    {{ error }}
                </b-alert>

                <div>
                    Last Four of Card Number
                    <span class="text-secondary"> (optional) </span>
                    <b-form-input size="lg" v-model="lastFour"></b-form-input>
                </div>

                <div class="mt-3">
                    Postal Code
                    <span class="text-secondary"> (optional) </span>
                    <b-form-input size="lg" v-model="postalCode"></b-form-input>
                </div>

                <div class="mt-3">
                    IP Address
                    <span class="text-secondary"> (optional) </span>
                    <b-form-input size="lg" v-model="ipAddress"></b-form-input>
                </div>

                <b-btn
                    :disabled="saving"
                    @click="save"
                    size="lg"
                    class="mt-3"
                    block
                    :variant="saved ? 'success' : 'primary'"
                >
                    <div v-if="saved">
                        <font-awesome-icon icon="fa-light fa-check-circle" />
                    </div>
                    <div v-else>Save</div>
                </b-btn>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            error: null,
            saved: false,
            saving: false,
            blacklist: null,
            lastFour: null,
            postalCode: null,
            ipAddress: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/blacklist/${this.$route.params.id}/get`)
                .then((response) => {
                    this.blacklist = response?.data?.blacklist;

                    this.lastFour = this.blacklist?.lastFour;
                    this.postalCode = this.blacklist?.postalCode;
                    this.ipAddress = this.blacklist?.ipAddress;
                });
        },
        deleteBlacklist() {
            if (confirm("Are you sure?")) {
                this.api
                    .post(`/blacklist/${this.$route.params.id}/delete`, {})
                    .then(() => {
                        this.$router.push({
                            name: "blacklistIndex",
                        });
                    });
            }
        },
        save() {
            this.saving = true;
            this.error = null;

            this.api
                .post(`/blacklist/${this.$route.params.id}/save`, {
                    lastFour: this.lastFour,
                    postalCode: this.postalCode,
                    ipAddress: this.ipAddress,
                })
                .then(() => {
                    this.refresh();

                    this.saved = true;

                    setTimeout(() => {
                        this.saved = false;
                    }, 3000);
                })
                .catch((error) => {
                    this.error = error?.response?.data?.message;
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>