<template>
    <div>
        <div class="my-3">
            <b-dropdown size="sm" variant="light" text="Available Meta">
                <b-dropdown-item
                    :key="availableMeta.id"
                    v-for="availableMeta in availableMetas"
                    @click="setMeta(availableMeta)"
                >
                    {{ availableMeta.title }}
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th style="width: 1%"></th>
                    <th>Key</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="meta.id" v-for="meta in metas">
                    <td>
                        <b-btn
                            @click="removeMeta(meta)"
                            size="sm"
                            variant="light"
                            class="text-danger text-truncate"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Remove
                        </b-btn>
                    </td>
                    <td>{{ meta.key }}</td>
                    <td>{{ meta.createdRelative }}</td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            availableMetas: null,
            metas: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        setMeta(meta) {
            this.api
                .post(`/userMeta/${this.user.model.id}/set`, {
                    key: meta.key,
                })
                .then(() => {
                    this.refresh();
                });
        },
        removeMeta(meta) {
            this.api
                .post(`/user/${this.user.model.id}/deleteMeta/${meta.id}`)
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/metas`)
                .then((response) => {
                    this.metas = response.data.metas;
                });

            this.api
                .post(`/userMeta/${this.user.model.id}/available`)
                .then((response) => {
                    this.availableMetas = response.data.availableMetas;
                });
        },
    },
};
</script>