<template>
    <b-nav-item-dropdown text="Appearance">
        <b-dropdown-item
            variant="danger"
            @click="changeAppearance('appearanceHot')"
        >
            Hot
        </b-dropdown-item>
        <b-dropdown-item
            variant="warning"
            @click="changeAppearance('appearanceCute')"
        >
            Cute
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="changeAppearance('appearanceNormal')">
            Normal
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
            @click="changeAppearance('appearanceUgly')"
            variant="info"
        >
            <font-awesome-icon icon="fa-duotone fa-face-zany" />
            Ugly
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>



<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async changeAppearance(endpoint) {
            const url = `/user/${this.user.model.id}/${endpoint}`;
            await this.api.post(url);
            this.$store.dispatch("userEdit/refresh");

            // shortcut
            // kind of a hack but just a one off
            if (endpoint === "appearanceUgly" && this.$route.query?.vetting) {
                this.$router.push("/vetting");
            }
        },
    },
};
</script>