<template>
    <div>
        <div v-if="currentUser">
            <primary-navigation />
            <router-view></router-view>
        </div>
        <div v-else-if="tryingToLogin">
            <generic-loading />
        </div>
        <div v-else>
            <login-email />
        </div>
    </div>
</template>

<style lang="scss">
@import "@/scss/dark.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
@import "@/scss/app.scss";
</style>

<script>
import { mapState } from "vuex";

import PrimaryNavigation from "@/components/navigation/PrimaryNavigation";
import LoginEmail from "@/components/login/LoginEmail";

export default {
    mounted() {
        const authToken = localStorage.getItem("authToken");

        if (authToken) {
            this.$store.dispatch("user/login", authToken);
        }
    },
    computed: {
        tryingToLogin() {
            return localStorage.getItem("authToken") && !this.currentUser;
        },
        ...mapState("user", ["currentUser"]),
    },
    components: {
        LoginEmail,
        PrimaryNavigation,
    },
};
</script>
        