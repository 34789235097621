<template>
    <b-tr v-if="user.brand">
        <b-th class="bg-light" style="width: 45%"> Brand </b-th>
        <b-td class="py-0 align-middle">
            <img class="rounded" height="24" width="24" :src="user.brand.logo100" />
            {{ user.brand.name }}
        </b-td>
    </b-tr>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async resetLastActive() {
            await this.api.post(`/user/${this.user.model.id}/resetLastActive`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>