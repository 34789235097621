<template>
    <b-tr v-if="user.coordinates">
        <b-th class="bg-light"> GPS </b-th>
        <b-td class="py-0 align-middle">
            <b-btn-group class="w-100">
                <b-btn
                    :href="`https://maps.google.com?q=${user.coordinates}`"
                    target="_blank"
                    size="sm"
                    variant="light"
                >
                    Map
                </b-btn>
                <b-btn
                    @click="openModal"
                    target="_blank"
                    size="sm"
                    variant="light"
                >
                    Nearby
                </b-btn>
            </b-btn-group>
        </b-td>
        <b-modal
            centered
            v-model="modal"
            title="Nearby Users"
            hide-footer
            body-class="p-0"
        >
            <user-nearby />
        </b-modal>
    </b-tr>
</template>

<script>
import { mapState } from "vuex";

import UserNearby from "@/components/user/UserNearby";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        openModal() {
            this.modal = true;
        },
    },
    components: {
        UserNearby,
    },
};
</script>