<template>
    <b-alert
        variant="primary"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container>
            <div>
                <h1>Screening</h1>
            </div>

            <div class="d-flex align-items-center justify-content-around mt-3">
                <div>
                    <b-btn
                        :disabled="pauseScreening"
                        @click="appearanceNormal"
                        variant="primary"
                        size="lg"
                        class="px-5"
                        block
                    >
                        Normal
                    </b-btn>
                </div>
                <div class="mt-3">
                    <b-btn-group class="w-100">
                        <b-btn
                            :disabled="pauseScreening"
                            @click="appearanceUgly"
                            class="border"
                            variant="secondary"
                        >
                            Ugly
                        </b-btn>
                        <b-btn
                            :disabled="pauseScreening"
                            @click="appearanceCute"
                            class="border"
                            variant="secondary"
                        >
                            Cute
                        </b-btn>
                        <b-btn
                            :disabled="pauseScreening"
                            @click="appearanceHot"
                            class="border"
                            variant="secondary"
                        >
                            Hot
                        </b-btn>
                    </b-btn-group>

                    <div class="mt-3">
                        <b-button
                            v-b-toggle.screeningHelp
                            variant="info"
                            size="sm"
                            class="ml-2"
                        >
                            Training
                        </b-button>
                        <b-collapse id="screeningHelp">
                            <b-alert
                                v-if="trainingWheels"
                                variant="info"
                                show
                                class="small mt-3"
                            >
                                <font-awesome-icon
                                    icon="fa-duotone fa-info-circle"
                                    size="lg"
                                />
                                Screening is our first look at the user. You
                                don't need to spend a lot of time, its more just
                                to take a quick look at them, and make sure its
                                not an obviously fake profile, or wrong gender,
                                futher review will happen in vetting if needed

                                <h5 class="mt-3">Rating Criteria</h5>
                                <div>
                                    Try to be objective, and not rate their
                                    attractiveness from your own point of view,
                                    but from the point of view of their peers
                                </div>
                                <b-table-simple bordered striped class="mt-2">
                                    <b-tbody>
                                        <b-tr>
                                            <b-th> Normal </b-th>
                                            <b-td>
                                                Average appearance, not
                                                unusually attractive, nor
                                                unpleasant to look at
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th> Ugly </b-th>
                                            <b-td>
                                                Significant facial defect,
                                                morbidly obese or universally
                                                unpleasant to look at by almost
                                                anyone
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th> Cute </b-th>
                                            <b-td>
                                                Very attractive, amongst 100 of
                                                their peers they would be in the
                                                top 10%
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th> Hot </b-th>
                                            <b-td>
                                                Exceptionally attractive,
                                                amongst 100 of their peers they
                                                would be the absolute most
                                                attractive
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>

                                <h5 class="mt-3">Functionality</h5>
                                <b-table-simple bordered striped>
                                    <b-tbody>
                                        <b-tr>
                                            <b-th> Normal </b-th>
                                            <b-td>
                                                The user will show in discovery
                                                feeds unless their country has
                                                an excessive amount of users
                                                (for example PH)
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th> Ugly </b-th>
                                            <b-td>
                                                The user will be hidden from all
                                                discovery feeds, and removed
                                                from most moderation queues
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th> Cute &amp; Hot </b-th>
                                            <b-td>
                                                The user will show in all
                                                discovery feeds no matter what,
                                                and be featured on the daily
                                                hotlist sent to men, and select
                                                brands
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-alert>
                        </b-collapse>
                    </div>
                </div>
                <div class="mt-3">
                    <b-dropdown text="More Options" size="lg" right>
                        <b-dropdown-item
                            :disabled="pauseScreening"
                            @click="idRequired"
                            class="py-3"
                        >
                            Photo ID Required
                            <div class="small text-secondary">
                                Suspected minors, wrong gender
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item
                            :disabled="pauseScreening"
                            @click="vettingRequired"
                            class="py-3"
                        >
                            Vetting Required
                            <div class="small text-secondary">
                                Suspected fake, wrong gender
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div
                v-if="user.flags.isLadyboy"
                class="p-3 rounded border border-warning mt-3"
            >
                <h3>
                    Ladyboy - or wrong gender?
                    <b-button
                        v-b-toggle.ladyboyScreening
                        variant="info"
                        size="sm"
                        class="ml-2"
                    >
                        <font-awesome-icon icon="fa-duotone fa-info-circle" />
                        Criteria
                    </b-button>
                    <b-collapse id="ladyboyScreening">
                        <ul class="small">
                            <li>Long, feminine hair</li>
                            <li>Female makeup/clothing</li>
                            <li>Fully transitioned to female/appears female</li>
                        </ul>
                    </b-collapse>
                </h3>

                <b-btn @click="manSeekingMan" variant="light">
                    Change to Man Seeking Man
                </b-btn>
            </div>
        </b-container>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            pauseScreening: true,
        };
    },
    mounted() {
        setTimeout(() => {
            this.pauseScreening = false;
        }, 1000);
    },
    computed: {
        ...mapState("userEdit", ["user"]),
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        pause() {
            this.pauseScreening = true;

            setTimeout(() => {
                this.pauseScreening = false;
            }, 3000);
        },
        exitScreenMode() {
            this.$router.push(`/user/edit/${this.user.model.id}`);
        },
        async screened() {
            this.pause();

            await this.api.post(`/screen/${this.user.model.id}/screened`);

            this.$router.push("/screen");
        },
        async appearanceNormal() {
            this.pause();

            await this.api.post(
                `/screen/${this.user.model.id}/appearanceNormal`
            );

            this.$router.push("/screen");
        },
        async appearanceUgly() {
            this.pause();

            await this.api.post(`/screen/${this.user.model.id}/appearanceUgly`);

            this.$router.push("/screen");
        },
        async appearanceCute() {
            this.pause();

            await this.api.post(`/screen/${this.user.model.id}/appearanceCute`);

            this.$router.push("/screen");
        },
        async appearanceHot() {
            this.pause();

            await this.api.post(`/screen/${this.user.model.id}/appearanceHot`);

            this.$router.push("/screen");
        },
        async vettingRequired() {
            this.pause();

            await this.api.post(
                `/screen/${this.user.model.id}/vettingRequired`
            );

            this.$router.push("/screen");
        },
        async idRequired() {
            this.pause();

            await this.api.post(`/screen/${this.user.model.id}/idRequired`);

            this.$router.push("/screen");
        },
        async manSeekingMan() {
            this.pause();

            await this.api.post(`/user/${this.user.model.id}/reassignGender`, {
                gender: 1,
                seeking: 1,
            });

            this.$router.push("/screen");
        },
    },
};
</script>