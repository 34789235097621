<template>
    <div class="bg-light text-center py-5">
        <h3>
            {{ $t("Page not found") }}
        </h3>

        <div class="my-3">
            {{ $t("Whoops we couldn't seem to find this page") }}
        </div>

        <div class="text-center my-3">
            <b-btn
                @click="$store.dispatch('navigation/home')"
                variant="primary"
                size="lg"
                pill
            >
                {{ $t("Back to Home") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.api.post("/spa/notFound", {
            url: document.location.href,
        });
    },
};
</script>
