<template>
    <b-alert :show="show" variant="danger" class="mb-0 border-0 rounded-0">
        <div :key="error" v-for="error in errors">
            {{ error }}
        </div>
    </b-alert>
</template>

<script>
export default {
    computed: {
        show() {
            return this.errors && this.errors.length > 0;
        },
    },
    props: ["errors"],
};
</script>
