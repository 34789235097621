<template>
    <div>
        <b-form-input
            size="lg"
            v-model="shame"
            placeholder="Shame reason"
        ></b-form-input>

        <b-btn @click="save" size="lg" class="mt-2" block variant="primary">
            Save
        </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            shame: null,
        };
    },
    mounted() {
        this.shame = this.user.model.shame;
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async save() {
            await this.api.post(`/user/${this.user.model.id}/shame`, {
                shame: this.shame,
            });

            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>