<template>
    <div>
        <b-navbar toggleable="sm" type="light" variant="light" fixed>
            <b-navbar-toggle targ target="nav-user-actions"></b-navbar-toggle>

            <b-collapse id="nav-user-actions" is-nav>
                <b-navbar-nav>
                    <user-action-status />

                    <user-action-appearance />
                    <user-action-allow />
                    <user-action-fine />
                    <user-action-flags />
                    <user-action-gender />
                    <user-action-email />
                    <user-action-root v-if="isRoot" />
                    <b-nav-form>
                        <user-action-note />
                        <user-action-wallet />
                    </b-nav-form>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                        <b-btn
                            target="_blank"
                            :href="user.publicUrl"
                            variant="light"
                            size="sm"
                            class="border mr-1"
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-arrow-up-right-from-square"
                            />
                        </b-btn>
                        <b-btn
                            target="_blank"
                            :href="user.oldSystemUrl"
                            variant="light"
                            size="sm"
                            class="border mr-1"
                        >
                            Old System
                        </b-btn>
                        <b-btn
                            @click="reset"
                            variant="light"
                            size="sm"
                            class="border"
                        >
                            <b-spinner small v-if="resetting" />
                            <font-awesome-icon
                                v-else
                                icon="fa-light fa-rotate-left"
                            />
                        </b-btn>
                    </b-nav-form>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserActionNote from "@/components/user/action/UserActionNote";
import UserActionWallet from "@/components/user/action/UserActionWallet";

import UserActionStatus from "@/components/user/action/UserActionStatus";
import UserActionAppearance from "@/components/user/action/UserActionAppearance";
import UserActionAllow from "@/components/user/action/UserActionAllow";
import UserActionFine from "@/components/user/action/UserActionFine";
import UserActionFlags from "@/components/user/action/UserActionFlags";
import UserActionGender from "@/components/user/action/UserActionGender";
import UserActionEmail from "@/components/user/action/UserActionEmail";
import UserActionRoot from "@/components/user/action/UserActionRoot";

export default {
    data() {
        return {
            resetting: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user", "tabs"]),
        ...mapState("user", ["isRoot"]),
    },
    methods: {
        async reset() {
            this.resetting = true;
            await this.api.post(`/user/${this.user.model.id}/reset`);
            this.resetting = false;
            this.$store.dispatch("userEdit/refresh");
        },
    },
    components: {
        UserActionNote,
        UserActionStatus,
        UserActionWallet,
        UserActionFlags,
        UserActionAppearance,
        UserActionAllow,
        UserActionRoot,
        UserActionFine,
        UserActionEmail,
        UserActionGender,
    },
};
</script>