<template>
    <generic-page>
        <b-container>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1 class="mb-0">Blacklist</h1>
                </div>
                <div>
                    <b-btn variant="primary" href="/blacklist/create">
                        Create
                    </b-btn>
                </div>
            </div>

            <b-table-simple striped class="mt-3">
                <b-thead>
                    <b-th> Last Four </b-th>
                    <b-th> Postal Code </b-th>
                    <b-th> IP </b-th>
                    <b-th> Created </b-th>
                    <b-th> </b-th>
                </b-thead>
                <b-tbody>
                    <b-tr :key="blacklist.id" v-for="blacklist in blacklists">
                        <td>{{ blacklist.lastFour }}</td>
                        <td>{{ blacklist.postalCode }}</td>
                        <td>{{ blacklist.ipAddress }}</td>
                        <td>{{ blacklist.createdRelative }}</td>
                        <td style="width: 1%">
                            <b-btn
                                variant="light"
                                size="sm"
                                class="text-primary text-truncate"
                                :to="`/blacklist/view/${blacklist.id}`"
                            >
                                View
                            </b-btn>
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            blacklists: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api.post("/blacklist/index", {}).then((response) => {
                this.blacklists = response.data.blacklists;
            });
        },
    },
};
</script>