<template>
    <generic-page>
        <b-container>
            <div v-if="userKeyword">
                <h3>
                    {{ keyword.keyword }}
                </h3>

                userKeyword: {{ userKeyword }}
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            userKeyword: null,
            keyword: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            const userKeywordId = this.$route.params.id;

            this.api
                .post(`/keyword/${userKeywordId}/instance`)
                .then((response) => {
                    this.userKeyword = response.data.userKeyword;
                    this.keyword = response.data.keyword;
                });
        },
    },
};
</script>