<template>
    <generic-page>
        <b-container>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1>Keyword Review</h1>
                </div>
                <div>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="filter">
                                ({{ filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('monitor')">
                            Monitor
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('ignore')">
                            Ignore
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <b-table-simple striped>
                    <b-thead>
                        <th>User</th>
                        <th>Keywords</th>
                        <th></th>
                    </b-thead>
                    <b-tbody>
                        <b-tr
                            :key="userWithKeyword.user.model.id"
                            v-for="userWithKeyword in filteredUsersWithKeywords"
                        >
                            <td style="width: 1%" class="align-middle">
                                <b-btn
                                    variant="light"
                                    size="lg"
                                    @click="screened(userWithKeyword)"
                                    class="mr-2 border"
                                >
                                    Screened
                                </b-btn>
                            </td>
                            <td style="width: 1%" class="align-middle">
                                <user-avatar :user="userWithKeyword.user" />
                            </td>
                            <td>
                                <b-link
                                    :to="`/keyword/instance/${userKeyword.id}`"
                                    :class="getKeywordClass(userKeyword)"
                                    :key="userKeyword.id"
                                    v-for="userKeyword in userWithKeyword.userKeywords"
                                >
                                    {{ userKeyword.keyword.keyword }}
                                </b-link>
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            loading: false,
            filter: null,
            usersWithKeywords: null,
            screenedUserIds: [],
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        filteredUsersWithKeywords() {
            return this.usersWithKeywords?.filter(
                (userWithKeyword) =>
                    !this.screenedUserIds.includes(
                        userWithKeyword.user.model.id
                    )
            );
        },
    },
    methods: {
        screened(userWithKeyword) {
            this.screenedUserIds.push(userWithKeyword.user.model.id);
            this.api.post(`/keyword/${userWithKeyword.user.model.id}/screened`);
        },
        getKeywordClass(userKeyword) {
            let classes = [];

            if (userKeyword.screened) {
                classes.push("opacity-50 text-dark");
            } else {
                if (userKeyword.harmless) {
                    classes.push("text-success");
                } else if (userKeyword.harmless === 0) {
                    classes.push("text-danger");
                } else {
                    classes.push("text-warning");
                }
            }

            return classes.join(" ");
        },
        refresh() {
            this.loading = true;

            this.api
                .post("/keyword/review", {
                    filter: this.filter,
                })
                .then((response) => {
                    this.usersWithKeywords = response.data.usersWithKeywords;
                    this.loading = false;
                });
        },
    },
    components: {},
};
</script>