<template>
    <generic-page>
        <b-container fluid>
            <b-alert
                show
                variant="light"
                v-if="$route.query?.screen"
                class="text-center py-5"
            >
                <h1>Screen Transactions</h1>

                <b-btn
                    variant="primary"
                    size="lg"
                    @click="markScreened"
                    class="mt-3"
                >
                    Mark All Screened
                </b-btn>
            </b-alert>

            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1 class="mb-0">Transactions</h1>
                </div>
                <div>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="$route.query?.filter">
                                ({{ $route.query.filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('unscreened')">
                            Unscreened (default)
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <authorize-transaction-table
                :authorizeTransactionIndex="authorizeTransactionIndex"
                :withAvatar="true"
            />
        </b-container>
    </generic-page>
</template>


<script>
import AuthorizeTransactionTable from "@/components/authorizeTransaction/AuthorizeTransactionTable";

export default {
    data() {
        return {
            authorizeTransactionIndex: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        markScreened() {
            this.api
                .post("/authorizeTransaction/screened", {
                    ids: this.authorizeTransactionIndex.map(
                        (t) => t.authorizeTransaction.id
                    ),
                })
                .then(() => {
                    this.$router.push("/");
                });
        },
        setFilter(filter) {
            const query = {};

            if (filter) {
                query.filter = filter;
            }

            this.$router.push({
                name: "withdrawIndex",
                query,
            });

            this.refresh();
        },
        refresh() {
            this.api
                .post("/authorizeTransaction/index", {
                    filter: this.$route.query?.filter,
                    screen: this.$route.query?.screen,
                })
                .then((response) => {
                    this.authorizeTransactionIndex =
                        response.data.authorizeTransactionIndex;
                });
        },
    },
    components: {
        AuthorizeTransactionTable,
    },
};
</script>