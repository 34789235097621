
<template>
    <generic-page>
        <b-container>
            <h1 class="mt-3">History</h1>

            <h3 class="mt-3">Recently Viewed</h3>
            <b-row no-gutters>
                <b-col
                    cols="3"
                    md="1"
                    :key="user.id"
                    v-for="user in ninjaViewUsers"
                >
                    <user-avatar :user="user" />
                </b-col>
            </b-row>

            <h3 class="mt-3">Audit Trail</h3>
            <b-table-simple class="small">
                <b-thead>
                    <tr>
                        <th>Type</th>
                        <th style="width: 45%">Old Values</th>
                        <th style="width: 45%">New Values</th>
                        <th></th>
                    </tr>
                </b-thead>
                <b-tbody>
                    <b-tr :key="history.id" v-for="history in historys">
                        <td>
                            {{ history.type }}
                            <div>
                                <b-link
                                    v-if="history.type === 'User'"
                                    :to="`/user/edit/${history.relatedId}`"
                                >
                                    {{ history.relatedId }}
                                </b-link>
                                <span v-else>
                                    {{ history.relatedId }}
                                </span>
                            </div>
                        </td>
                        <td class="bg-light">
                            <pre class="audit mb-0 small text-">{{
                                history.oldValues
                            }}</pre>
                        </td>
                        <td>
                            <pre class="audit mb-0 small text-">{{
                                history.newValues
                            }}</pre>
                        </td>
                        <td>
                            {{ history.createdRelative }}
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-container>
    </generic-page>
</template>
<script>
export default {
    data() {
        return {
            historys: null,
            ninjaViewUsers: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.historys = null;

            this.api.post("/history/index").then((response) => {
                this.historys = response.data.historys;
                this.ninjaViewUsers = response.data.ninjaViewUsers;
            });
        },
    },
};
</script>