<template>
    <div>
        <div class="mt-4">
            {{ relatedUsers?.length }} Related Users
            <b-dropdown class="ml-3" text="Limit" variant="light" size="sm">
                <b-dropdown-item
                    :key="limitOption"
                    v-for="limitOption in limitOptions"
                    @click="updateLimit(limitOption)"
                >
                    Show {{ limitOption }}
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <div v-if="refreshing">
            <generic-loading />
        </div>
        <div v-else>
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    :key="relatedUser.user.id"
                    v-for="relatedUser in relatedUsers"
                    class="p-3 small"
                >
                    <div class="d-flex align-items-start">
                        <div class="mr-2">
                            <user-avatar :user="relatedUser.user" />
                        </div>

                        <div>
                            <div
                                v-if="relatedUser.exactMatch"
                                class="text-warning small"
                            >
                                <font-awesome-icon
                                    icon="fa-duotone fa-triangle-exclamation"
                                />
                                Exact Match
                            </div>
                            <b-badge variant="light" class="mr-2">
                                {{ relatedUser.relationshipStrength }}
                                <span class="text-capitalize text-secondary">
                                    Match Strength
                                </span>
                            </b-badge>
                            <b-badge
                                variant="light"
                                class="mr-2"
                                :key="item"
                                v-for="(count, item) in relatedUser.types"
                            >
                                {{ count }}
                                <span class="text-capitalize text-secondary">
                                    {{ item }}
                                </span>
                            </b-badge>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            limitOptions: [100, 200, 500, 1000],
            limit: null,
            refreshing: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user", "relatedUsers"]),
    },
    mounted() {
        // let logs go first
        if (!this.relatedUsers) {
            setTimeout(() => {
                this.refresh();
            }, 50);
        }
    },
    methods: {
        updateLimit(limit) {
            this.limit = limit;
            this.refresh();
        },
        isNew(log) {
            return new Date(log.created) > this.created;
        },
        refresh() {
            this.refreshing = true;

            this.api
                .post(`/user/${this.user.model.id}/relatedUsers`, {
                    limit: this.limit,
                })
                .then((response) => {
                    this.refreshing = false;

                    this.$store.commit(
                        "userEdit/setRelatedUsers",
                        response.data.relatedUsers
                    );
                });
        },
    },
};
</script>