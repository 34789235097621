<template>
    <div>
        <b-button @click="modal = true" variant="info" size="sm" class="mt-3">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Training
        </b-button>
        <b-modal centered v-model="modal" title="Training" hide-footer>
            <b-alert variant="info" show>
                <h3>Unmoderated Content</h3>

                <div class="mt-3">
                    Unlike photos, the videos uploaded here do not undergo any
                    moderation. We are currently only doing manual approvals so
                    you are the only check to make sure the video is allowed, be
                    cautious!
                </div>

                Reject any videos that are boring, unattractive, not appealing.
                This is premium real-estate, only videos that are really going
                to grab someones attention should be approved.

                <div class="mt-3">
                    Videos that actually violate our policies, especially adult
                    content, minors, or inappropriate videos should get a fine,
                    which issues a generic 1 credit penalty to the user to
                    incentivize them to correct their behavior.
                </div>

                <div class="mt-3">
                    Approve videos (thumbs up) that are quality, attractive,
                    high standard. Mark any videos filler that are low to medium
                    quality. Since we already paid to process the video, these
                    will just be at the end of the feed to squeeze a few more
                    views out of users
                </div>

                <div class="mt-3">
                    <h5>Prohibited Videos</h5>
                    <ul>
                        <li>
                            Adult content, including bra, panties, lingirie,
                            etc. Swimwear (bikini, etc) is OK, as long as its
                            non-sexual. If they are making sexual poses, reject
                            it.
                        </li>
                        <li>
                            Minors, or large groups of people where you can't
                            see who the creator clearly is. It should mostly
                            focus on the creator. Its ok if there is
                            occasionally others in the video, as long as its
                            mostly featuring the creator
                        </li>
                        <li>
                            Photo galleries, still videos, AI, fake, or
                            otherwise non-normal videos. We're looking for
                            normal videos of people, not animated galleries, AI
                            videos, etc.
                        </li>
                        <li>
                            No promotion - if they are promoting their OnlyFans,
                            Instagram, etc. Its not allowed. The one exception
                            is TikTok, since they are so prevalent, and its not
                            as much as a communication platform, its gets a pass
                        </li>
                        <li>
                            No drinking, drugs, smoking, guns or anything
                            illegal. The point of these videos is not to shock
                            people, but to entertain. These videos may be used
                            to publicly advertise the app, so they should be
                            "PG-13"
                        </li>
                        <li>
                            Sexy is OK, Sexual is NOT. Its hard to say exactly
                            where to draw the line. In general, we should be
                            conservative, especially about showing private body
                            parts, even through clothing. Very thin swimwear
                            cannot be allowed, it should fully cover and conceal
                            all private parts fully and completely.
                        </li>
                        <li>
                            Fake - or no Face. You must be able to see the face
                            of the creator for at least a second in the video,
                            if they never appear, reject it. If its their head
                            on another body, reject it
                        </li>
                    </ul>
                </div>
            </b-alert>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modal: false,
        };
    },
};
</script>