<template>
    <div>
        <b-btn
            @click="openModal"
            variant="light"
            size="sm"
            class="border text-warning"
        >
            <font-awesome-icon icon="fa-duotone fa-sticky-note" />
        </b-btn>

        <b-modal
            @shown="$refs.focus.focus()"
            centered
            v-model="modal"
            title="Add Note"
            hide-footer
        >
            <b-form @submit.stop.prevent="addNote">
                <b-form-group label="Add your note">
                    <b-textarea
                        ref="focus"
                        size="lg"
                        v-model="note"
                        type="text"
                        autocorrect="off"
                        autocomplete="off"
                        autocapitalize="none"
                    />
                </b-form-group>
                <b-form-group>
                    <b-btn @click="addNote" variant="primary" size="lg" block>
                        Add Note
                    </b-btn>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            note: null,
            modal: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        openModal() {
            this.modal = true;
        },
        addNote() {
            this.api
                .post(`/user/${this.user.model.id}/note`, {
                    note: this.note,
                })
                .then(() => {
                    this.note = null;
                    this.modal = false;
                    this.$store.dispatch("userEdit/refresh");
                });
        },
    },
};
</script>