<template>
    <generic-page>
        <b-container>
            <h1 class="mt-3">Create Blacklist Entry</h1>

            <b-alert variant="primary" show class="small">
                At least one criteria is required
            </b-alert>
            <b-alert variant="danger" show class="small" v-if="error">
                {{ error }}
            </b-alert>

            <div>
                Last Four of Card Number
                <span class="text-secondary"> (optional) </span>
                <b-form-input size="lg" v-model="lastFour"></b-form-input>
            </div>

            <div class="mt-3">
                Postal Code
                <span class="text-secondary"> (optional) </span>
                <b-form-input size="lg" v-model="postalCode"></b-form-input>
            </div>

            <div class="mt-3">
                IP Address
                <span class="text-secondary"> (optional) </span>
                <b-form-input size="lg" v-model="ipAddress"></b-form-input>
            </div>

            <b-btn
                :disabled="saving"
                @click="save"
                size="lg"
                class="mt-3"
                block
                variant="primary"
            >
                Save
            </b-btn>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            error: null,
            saved: false,
            saving: false,
            blacklist: null,
            lastFour: null,
            postalCode: null,
            ipAddress: null,
        };
    },
    mounted() {
        if (this.$route?.query?.lastFour) {
            this.lastFour = this.$route?.query?.lastFour;
        }
        if (this.$route?.query?.postalCode) {
            this.postalCode = this.$route?.query?.postalCode;
        }
    },
    methods: {
        save() {
            this.saving = true;
            this.error = null;

            this.api
                .post(`/blacklist/create`, {
                    lastFour: this.lastFour,
                    postalCode: this.postalCode,
                    ipAddress: this.ipAddress,
                })
                .then((response) => {
                    if (response?.data?.blacklist) {
                        this.$router.push(
                            `/blacklist/view/${response?.data?.blacklist?.id}`
                        );
                    }
                })
                .catch((error) => {
                    this.error = error?.response?.data?.message;
                })
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>