<template>
    <div>
        <div class="my-3">
            <b-dropdown variant="danger" text="Bulk Actions">
                <b-dropdown-item variant="danger" @click="confirmBan = true">
                    <font-awesome-icon icon="fa-light fa-times" />
                    Ban All Unvetted Users
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <b-alert variant="danger" show v-if="confirmBan" class="mt-3">
            <h3>Are you ABSOLUTELY Sure?</h3>

            <h3>
                This will ban all invited users, except any buyers, those need
                to be manually banned. Bans will run async to prevent crashes,
                refresh after 30 seconds to check status.
            </h3>

            <b-btn
                variant="danger"
                @click="bulkBan"
                size="lg"
                class="px-5 my-3"
            >
                Ban All Invited Users
            </b-btn>
        </b-alert>

        <b-table-simple striped>
            <b-thead>
                <b-th> User </b-th>
                <b-th> Spend </b-th>
                <b-th> Total Earnings </b-th>
                <b-th> Days Active </b-th>
                <b-th> EPDA </b-th>
                <b-th> Created </b-th>
            </b-thead>
            <b-tbody>
                <b-tr :key="invite.user.model.id" v-for="invite in invites">
                    <td>
                        <user-avatar :user="invite.user" />
                    </td>
                    <td>
                        {{ invite.creditRefillTotal }}
                    </td>
                    <td>
                        {{ invite.totalCreditsEarned }}
                    </td>
                    <td>
                        {{ invite.daysActive }}
                    </td>
                    <td>
                        {{ invite.earningsPerDaysActive }}
                    </td>
                    <td>
                        {{ invite.createdRelative }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            invites: null,
            confirmBan: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/invites`)
                .then((response) => {
                    this.invites = response.data.invites;
                });
        },
        bulkBan() {
            this.api.post(`/user/${this.user.model.id}/bulkBan`);
        },
    },
    components: {},
};
</script>