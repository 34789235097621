<template>
    <b-alert
        variant="warning"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container>
            <div class="d-flex align-items-center justify-content-around mt-3">
                <b-btn
                    :disabled="pauseReviewing"
                    variant="primary"
                    @click="reset"
                    size="lg"
                    class="px-5"
                >
                    Reset
                </b-btn>

                <div>
                    <h1>Review #{{ user.reviewCount }}</h1>
                </div>

                <b-btn
                    v-if="user.flags.isBanned"
                    :disabled="pauseReviewing"
                    variant="primary"
                    href="/review"
                    size="lg"
                    class="px-5"
                >
                    Next
                </b-btn>
                <b-btn
                    v-else
                    :disabled="pauseReviewing"
                    variant="danger"
                    @click="ban"
                    size="lg"
                    class="px-5"
                >
                    Ban
                </b-btn>
            </div>

            <div v-if="user.reviewReason" class="mt-4">
                {{ user.reviewReason }}
            </div>

            <div v-if="reviews?.length" class="mt-3">
                <small> Past Reviews </small>
                <b-table-simple class="small" bordered striped>
                    <b-tbody>
                        <b-tr :key="review.id" v-for="review in reviews">
                            <td>
                                <b-badge class="text-capitalize">
                                    {{ review.decision }}
                                </b-badge>
                                {{ review.reviewReason }}
                            </td>

                            <td>{{ review.createdRelative }}</td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </b-container>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            pauseReviewing: true,
            reviews: null,
        };
    },
    mounted() {
        setTimeout(() => {
            this.pauseReviewing = false;
        }, 1000);

        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        next() {
            this.$router.push("/review");
        },
        async reset() {
            await this.api.post(`/review/${this.user.model.id}/reset`);
            this.$router.push("/review");
        },
        async ban() {
            await this.$store.dispatch("userEdit/ban");
            this.$router.push("/review");
        },
        exitReviewMode() {
            this.$router.push(`/user/edit/${this.user.model.id}`);
        },
        refresh() {
            this.api
                .post(`/review/${this.user.model.id}/index`)
                .then((response) => {
                    this.reviews = response.data.reviews;
                });
        },
    },
};
</script>