<template>
    <div>
        <h3>Details</h3>

        <b-table-simple class="userFields small" borderless>
            <b-tbody>
                <b-tr>
                    <b-th class="bg-light"> ID </b-th>
                    <b-td class="text-truncate">
                        {{ withdraw.id }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> User </b-th>
                    <b-td class="text-truncate">
                        <user-avatar v-if="user" :user="user" />
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> User ID </b-th>
                    <b-td class="text-truncate">
                        {{ withdraw.userId }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> Fulfilled </b-th>
                    <b-td class="text-truncate">
                        {{ withdraw.fulfilledAt }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> Amount </b-th>
                    <b-td class="text-truncate">
                        <coin-badge :amount="withdraw.amount"></coin-badge>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> Balance </b-th>
                    <b-td class="text-truncate">
                        <coin-badge :amount="withdraw.balanceInt"></coin-badge>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> Jackpot Fee </b-th>
                    <b-td class="text-truncate">
                        <coin-badge
                            :amount="withdraw.jackpotAmount"
                        ></coin-badge>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> Submitted </b-th>
                    <b-td class="text-truncate">
                        {{ withdraw.submittedAt }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="bg-light"> Status </b-th>
                    <b-td class="text-truncate">
                        {{ withdraw.transferStatus }}
                    </b-td>
                </b-tr>
                <b-tr v-if="invitedByUser">
                    <b-th class="bg-light"> Invited User </b-th>
                    <b-td class="text-truncate">
                        <user-avatar :user="invitedByUser" />
                    </b-td>
                </b-tr>
                <b-tr v-if="invitedByUser">
                    <b-th class="bg-light"> Inviter Commission </b-th>
                    <b-td class="text-truncate">
                        <coin-badge :amount="withdraw.commission"></coin-badge>
                    </b-td>
                </b-tr>
                <b-tr v-if="invitedByUser">
                    <b-th class="bg-light"> Inviter Balance </b-th>
                    <b-td class="text-truncate">
                        <coin-badge
                            :amount="invitedByUser.balance"
                        ></coin-badge>
                    </b-td>
                </b-tr>
                <b-tr v-if="withdraw.amountUsdFormatted">
                    <b-th class="bg-light"> Payout USD </b-th>
                    <b-td class="text-truncate">
                        <h2 class="text-success">
                            ${{ withdraw.amountUsdFormatted }}
                        </h2>
                        <button
                            class="btn btn-primary btn-lg w-100"
                            @click="copyText(withdraw.amountUsdFormatted)"
                        >
                            Copy
                        </button>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw", "user", "invitedByUser"]),
    },
    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
    },
};
</script>