<template>
    <generic-page>
        <b-container>
            <div v-if="tracker">
                {{ tracker.tracker }}

                <div>Created {{ tracker.createdRelative }}</div>

                <div
                    class="d-flex align-items-center justify-content-between my-3"
                >
                    <b-dropdown text="Actions">
                        <b-dropdown-item
                            variant="danger"
                            @click="deleteTracker"
                        >
                            Delete
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <div :key="user.model.id" v-for="user in users">
                    <user-avatar :user="user" />
                </div>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            tracker: null,
            users: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async deleteTracker() {
            await this.api.post(`/tracker/${this.tracker.id}/delete`);
            this.$router.push("/");
        },
        refresh() {
            const id = this.$route.params.id;

            this.api.post(`/tracker/${id}/get`).then((response) => {
                this.tracker = response.data.tracker;
                this.users = response.data.users;
            });
        },
    },
};
</script>