<template>
    <b-nav-item-dropdown text="Flags">
        <b-dropdown-item v-if="!user.model.criedWolf" @click="criedWolf">
            Cried Wolf
            <div class="text-secondary small">
                Reports have no impact and are ignored
            </div>
        </b-dropdown-item>

        <b-dropdown-item v-if="user.model.disregard" @click="removeDisregard">
            Remove Disregard
        </b-dropdown-item>
        <b-dropdown-item v-else @click="disregard">
            Disregard
            <div class="text-secondary small">User will not be moderated</div>
        </b-dropdown-item>

        <b-dropdown-item v-if="user.model.hidden" @click="removeHidden">
            Remove Hidden
        </b-dropdown-item>
        <b-dropdown-item v-else @click="hidden">
            Hidden
            <div class="text-secondary small">
                User will not be visible to others in the app
            </div>
        </b-dropdown-item>

        <b-dropdown-item v-if="user.model.creator" @click="removeCreator">
            Remove Creator
            <div class="text-secondary small">Disable creator access</div>
        </b-dropdown-item>
        <b-dropdown-item v-else @click="makeCreator">
            Creator
            <div class="text-secondary small">Access creator dashboard</div>
        </b-dropdown-item>

        <b-dropdown-item @click="bypassOnboarding">
            Bypass onboarding
            <div class="text-secondary small">
                (ask minimal questions to enter app)
            </div>
        </b-dropdown-item>

        <b-dropdown-item v-if="!user.model.expat" @click="expat">
            Expat
            <div class="text-secondary small">
                show to all users, allow card purchases
            </div>
        </b-dropdown-item>
        <b-dropdown-item v-else @click="removeExpat">
            Remove Expat
            <div class="text-secondary small">
                (hide from girls if outside >T2 country)
            </div>
        </b-dropdown-item>

        <b-dropdown-item v-if="user.model.censored" @click="removeCensored">
            Remove Censored
            <div class="text-secondary small">(show everything)</div>
        </b-dropdown-item>
        <b-dropdown-item v-else @click="makeCensored">
            Censored
            <div class="text-secondary small">(remove all racy features)</div>
        </b-dropdown-item>

        <b-dropdown-divider></b-dropdown-divider>

        <b-dropdown-item
            v-if="!user.flags.settingLockdown"
            variant="danger"
            @click="lockdown"
        >
            Lockdown
            <div class="text-secondary small">
                Prevent photo/profile updates
            </div>
        </b-dropdown-item>
        <b-dropdown-item v-else @click="removeLockdown">
            Remove Lockdown
            <div class="text-secondary small">Allow photo/profile updates</div>
        </b-dropdown-item>

        <b-dropdown-item
            v-if="!user.flags.idRequired"
            variant="danger"
            @click="idRequired"
        >
            Photo ID Required
        </b-dropdown-item>
        <b-dropdown-item v-else @click="removeIdRequired">
            Remove Photo ID Required
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user", "tabs"]),
    },
    methods: {
        async removeInvitesRequired() {
            await this.api.post(
                `/user/${this.user.model.id}/removeInvitesRequired`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async invitesRequired() {
            await this.api.post(`/user/${this.user.model.id}/invitesRequired`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeHighRiskBuyer() {
            await this.api.post(
                `/user/${this.user.model.id}/removeHighRiskBuyer`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async highRiskBuyer() {
            await this.api.post(`/user/${this.user.model.id}/highRiskBuyer`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeDisregard() {
            await this.api.post(`/user/${this.user.model.id}/removeDisregard`);
            this.$store.dispatch("userEdit/refresh");
        },
        async disregard() {
            await this.api.post(`/user/${this.user.model.id}/disregard`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeHidden() {
            await this.api.post(`/user/${this.user.model.id}/removeHidden`);
            this.$store.dispatch("userEdit/refresh");
        },
        async hidden() {
            await this.api.post(`/user/${this.user.model.id}/hidden`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeCreator() {
            await this.api.post(`/user/${this.user.model.id}/removeCreator`);
            this.$store.dispatch("userEdit/refresh");
        },
        async makeCreator() {
            await this.api.post(`/user/${this.user.model.id}/creator`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeAffiliate() {
            await this.api.post(`/user/${this.user.model.id}/removeAffiliate`);
            this.$store.dispatch("userEdit/refresh");
        },
        async makeAffiliate() {
            await this.api.post(`/user/${this.user.model.id}/affiliate`);
            this.$store.dispatch("userEdit/refresh");
        },
        async bypassOnboarding() {
            await this.api.post(`/user/${this.user.model.id}/bypassOnboarding`);
            this.$store.dispatch("userEdit/refresh");
        },
        async sugarbaby() {
            await this.api.post(`/user/${this.user.model.id}/sugarbaby`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeSugarbaby() {
            await this.api.post(`/user/${this.user.model.id}/removeSugarbaby`);
            this.$store.dispatch("userEdit/refresh");
        },
        async expat() {
            await this.api.post(`/user/${this.user.model.id}/expat`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeExpat() {
            await this.api.post(`/user/${this.user.model.id}/removeExpat`);
            this.$store.dispatch("userEdit/refresh");
        },
        async makeCensored() {
            await this.api.post(`/user/${this.user.model.id}/censored`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeCensored() {
            await this.api.post(`/user/${this.user.model.id}/censored`, {
                remove: true,
            });

            this.$store.dispatch("userEdit/refresh");
        },
        async lockdown() {
            await this.api.post(`/user/${this.user.model.id}/lockdown`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeLockdown() {
            await this.api.post(`/user/${this.user.model.id}/lockdown`, {
                remove: true,
            });

            this.$store.dispatch("userEdit/refresh");
        },
        async idRequired() {
            await this.api.post(`/user/${this.user.model.id}/idRequired`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeIdRequired() {
            await this.api.post(
                `/user/${this.user.model.id}/removeIdRequired`,
                {
                    remove: true,
                }
            );

            this.$store.dispatch("userEdit/refresh");
        },
        async criedWolf() {
            await this.api.post(`/user/${this.user.model.id}/criedWolf`);
            this.$store.dispatch("userEdit/refresh");
        },
        async remainVetted() {
            await this.api.post(`/user/${this.user.model.id}/remainVetted`);
            this.$store.dispatch("userEdit/refresh");
        },
        async allowTrackedKeywords() {
            await this.api.post(
                `/user/${this.user.model.id}/allowTrackedKeywords`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async allowNearbyHotspot() {
            await this.api.post(
                `/user/${this.user.model.id}/allowNearbyHotspot`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async allowInvalidLocale() {
            await this.api.post(
                `/user/${this.user.model.id}/allowInvalidLocale`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async whitelist() {
            await this.api.post(`/user/${this.user.model.id}/whitelist`);
            this.$store.dispatch("userEdit/refresh");
        },
        async carteblanche() {
            await this.api.post(`/user/${this.user.model.id}/carteblanche`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>