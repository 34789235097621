<template>
    <div>
        <b-alert
            v-if="user.model.invitesRequired"
            variant="info"
            show
            class="d-flex align-items-center justify-content-between"
        >
            <div>{{ user.model.invitesRequired }} male invites required</div>
            <b-btn @click="removeInvitesRequired" variant="light">
                Remove
            </b-btn>
        </b-alert>

        <b-alert v-if="user.flags.settingLockdown" variant="warning" show>
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <h5>
                        <font-awesome-icon
                            icon="fa-duotone fa-triangle-exclamation"
                        />
                        User is locked down
                    </h5>

                    <div class="my-2">
                        Photo and profile updates blocked until removed
                    </div>
                </div>
                <b-btn @click="removeLockdown" variant="light">
                    Remove Lockdown
                </b-btn>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async removeLockdown() {
            await this.api.post(`/user/${this.user.model.id}/lockdown`, {
                remove: true,
            });

            this.$store.dispatch("userEdit/refresh");
        },
        async removeInvitesRequired() {
            await this.api.post(
                `/user/${this.user.model.id}/removeInvitesRequired`
            );

            this.$store.dispatch("userEdit/refresh");
        },
        async removeHighRiskBuyer() {
            await this.api.post(
                `/user/${this.user.model.id}/removeHighRiskBuyer`
            );

            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>