<template>
    <b-alert
        variant="dark"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container>
            <b-alert v-if="buyerRisks?.length" variant="warning" show>
                <h5>Accept all risks to mark legit</h5>
                <b-table-simple striped bordered>
                    <thead>
                        <th>Accept</th>
                        <th>Risk</th>
                        <th>Created</th>
                    </thead>
                    <b-tbody>
                        <b-tr
                            :key="buyerRisk.id"
                            v-for="buyerRisk in buyerRisks"
                        >
                            <td style="width: 1%" class="align-middle">
                                <b-btn
                                    variant="primary"
                                    @click="screen(buyerRisk)"
                                >
                                    Screened
                                </b-btn>
                            </td>
                            <td>
                                {{ buyerRisk.risk }}
                            </td>
                            <td>
                                {{ buyerRisk.createdRelative }}
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-alert>

            <div class="d-flex align-items-center justify-content-around mt-3">
                <div>
                    <h1 class="mb-0">Buyer Risk</h1>
                </div>
            </div>
        </b-container>

        <b-button
            v-b-toggle.newBuyerTraining
            variant="info"
            size="sm"
            class="mt-3"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Training
        </b-button>
        <b-collapse id="newBuyerTraining">
            <div class="text-center mt-3">
                Buyers who have risk factors on their account can indicate
                fraud, escalate any accounts that have unacceptable risks
            </div>
        </b-collapse>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            buyerRisks: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        acceptRisk(risk) {
            this.accepted.push(risk.id);
        },
        refresh() {
            this.api
                .post(`/buyerRisk/${this.user.model.id}/index`)
                .then((response) => {
                    this.buyerRisks = response.data.buyerRisks;

                    if (!this.buyerRisks.length) {
                        this.$router.push("/buyerRisk");
                    }
                });
        },
        async screen(buyerRisk) {
            await this.api.post(`/buyerRisk/${buyerRisk.id}/screen`);
            this.refresh();
        },
    },
};
</script>