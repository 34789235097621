<template>
    <span class="circle-item" v-if="ninjaUser?.firstLetterOfName">
        {{ ninjaUser.firstLetterOfName }}
    </span>
</template>

<script>
export default {
    props: ["ninjaUser"],
};
</script>