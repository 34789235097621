<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>ID</th>
                    <th>Banned</th>
                    <th>User Count</th>
                    <th>Created</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    :key="tracker.id"
                    v-for="{ tracker, userCount } in trackers"
                >
                    <td>
                        <b-link :href="`/tracker/${tracker.id}`">
                            {{ tracker.tracker }}
                        </b-link>
                    </td>
                    <td :class="{ 'text-danger': tracker.banned }">
                        <i
                            v-if="tracker.banned"
                            class="fa-duotone fa-triangle-exclamation text-danger"
                        ></i>
                        {{ tracker.status }}
                    </td>
                    <td>{{ userCount }}</td>
                    <td>{{ tracker.created }}</td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            trackers: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/trackers`)
                .then((response) => {
                    this.trackers = response.data.trackers;
                });
        },
    },
};
</script>