<template>
    <generic-page>
        <b-container>
            <div v-if="authorizeTransaction" class="mt-3">
                <authorize-transaction-details
                    :authorizeTransaction="authorizeTransaction"
                />

                <b-btn
                    :disabled="saving"
                    @click="blacklist"
                    size="lg"
                    class="mt-3"
                    block
                    variant="dark"
                >
                    Blacklist Card Details
                </b-btn>

                <div v-if="!authorizeTransaction.voided">
                    <b-btn
                        @click="confirmVoid = true"
                        size="lg"
                        class="mt-3"
                        block
                        variant="danger"
                    >
                        Void Transaction
                    </b-btn>

                    <b-alert
                        variant="danger"
                        show
                        v-if="confirmVoid"
                        class="mt-3 text-center"
                    >
                        <h3>Are you ABSOLUTELY Sure?</h3>

                        <div class="my-3">
                            <font-awesome-icon
                                icon="fa-duotone fa-solid fa-skull-crossbones"
                                size="5x"
                            />
                            <font-awesome-icon
                                icon="fa-duotone fa-solid fa-skull-crossbones"
                                size="5x"
                            />
                            <font-awesome-icon
                                icon="fa-duotone fa-solid fa-skull-crossbones"
                                size="5x"
                            />
                        </div>

                        <b-btn
                            :disabled="pause"
                            variant="danger"
                            @click="executeVoid"
                            size="lg"
                            class="px-5 my-3"
                        >
                            Execute Void
                        </b-btn>
                    </b-alert>
                </div>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
import AuthorizeTransactionDetails from "@/components/authorizeTransaction/AuthorizeTransactionDetails";

export default {
    data() {
        return {
            authorizeTransaction: null,
            userCreditCard: null,
            confirmVoid: false,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        executeVoid() {
            this.api
                .post(`/authorizeTransaction/${this.$route.params.id}/void`)
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.api
                .post(`/authorizeTransaction/${this.$route.params.id}/get`)
                .then((response) => {
                    this.authorizeTransaction =
                        response?.data?.authorizeTransaction;
                    this.userCreditCard = response?.data?.userCreditCard;
                });
        },
        blacklist() {
            this.$router.push({
                name: "blacklistCreate",
                query: {
                    lastFour: this.userCreditCard.lastFour,
                    postalCode: this.authorizeTransaction.postalCode,
                },
            });
        },
    },
    components: {
        AuthorizeTransactionDetails,
    },
};
</script>