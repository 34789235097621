import Vue from 'vue'
import VueRouter from 'vue-router'

import system from './system'

Vue.use(VueRouter);

const routes = [
    ...system,
];

const router = new VueRouter({
    mode: process.env.VUE_APP_TARGET === 'cordova' ? 'hash' : 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router