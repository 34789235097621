<template>
    <generic-page>
        <b-container>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div></div>
                <div></div>
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                    <h1>Users ({{ remaining }} remaining)</h1>
                </div>
                <div>
                    <b-dropdown variant="light" right class="mr-2">
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="filter">
                                ({{ filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('hot')">
                            Hot
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Last Active
                            <span class="text-info" v-if="lastActive">
                                ({{ lastActive }})
                            </span>
                        </template>
                        <b-dropdown-item
                            :key="days"
                            v-for="days in [30, 90, 300, 1000]"
                            @click="setLastActive(days)"
                        >
                            {{ days }}
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setLastActive(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <b-table-simple striped>
                    <b-tbody>
                        <b-tr
                            :key="ninjaMap.model.id"
                            v-for="{ ninjaMap, videos } in filteredUsers"
                        >
                            <td class="lead align-middle">
                                <user-avatar :user="ninjaMap" />

                                <b-row no>
                                    <b-col
                                        md="4"
                                        cols="12"
                                        :key="video.id"
                                        v-for="video in videos"
                                    >
                                        <div
                                            class="rounded border mb-5"
                                            style="border-width: 5px !important"
                                            :class="{
                                                'border-success':
                                                    ninjaMap.model
                                                        .primaryVideoId ===
                                                    video.id,
                                            }"
                                        >
                                            <div class="text-center">
                                                {{ video.id }}
                                            </div>
                                            <video
                                                controls
                                                class="img-fluid m-0 p-0"
                                                :src="video.webmUrl"
                                                autoplay
                                                loop
                                                muted
                                            ></video>

                                            <b-btn
                                                v-if="
                                                    ninjaMap.model
                                                        .primaryVideoId !==
                                                    video.id
                                                "
                                                @click="
                                                    primary(ninjaMap, video)
                                                "
                                                variant="primary"
                                                block
                                            >
                                                Set Primary
                                            </b-btn>
                                        </div>
                                    </b-col>
                                </b-row>
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>

                <div class="p-3 mb-5">
                    <b-btn variant="primary" size="lg" block @click="screened">
                        Screened
                    </b-btn>
                </div>
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            loading: false,
            users: null,
            lastActive: 90,
            filter: null,
            remaining: null,
            screenedIds: [],
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        filteredUsers() {
            return this.users?.filter(
                (user) => !this.screenedIds.includes(user.ninjaMap.model.id)
            );
        },
    },
    methods: {
        setFilter(value) {
            this.filter = value;
            this.refresh();
        },
        setLastActive(value) {
            this.lastActive = value;
            this.refresh();
        },
        async primary(ninjaMap, userVideo) {
            this.screenedIds.push(ninjaMap.model.id);
            await this.api.post(`/verification/${userVideo.id}/primary`);
        },
        async screened() {
            await this.api.post(`/verification/screened`, {
                userIds: this.users.map((user) => user.ninjaMap.model.id),
            });

            this.refresh();
        },
        refresh() {
            //reset screened
            this.screenedFlags = [];
            this.loading = true;

            this.api
                .post("/verification/index", {
                    lastActive: this.lastActive,
                    filter: this.filter,
                })
                .then((response) => {
                    this.users = response.data.users;
                    this.remaining = response.data.remaining;
                    this.loading = false;
                });
        },
    },
    components: {},
};
</script>