<template>
    <b-nav-item-dropdown text="Allow">
        <b-dropdown-item @click="allowBannedFingerprint">
            Banned Fingerprint
        </b-dropdown-item>
        <b-dropdown-item @click="remainVetted">
            Remain Vetted with Photo Changes
        </b-dropdown-item>
        <b-dropdown-item @click="allowTrackedKeywords">
            Tracked Keywords
        </b-dropdown-item>
        <b-dropdown-item @click="allowNearbyHotspot">
            Nearby Hotspot
        </b-dropdown-item>
        <b-dropdown-item @click="allowInvalidLocale">
            Invalid Timezone
        </b-dropdown-item>
        <b-dropdown-item @click="whitelist">
            Whitelist - bypass IP &amp; GPS checks
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="carteblanche" variant="primary">
            Carte Blanche - Allow All
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user", "tabs"]),
    },
    methods: {
        async allowBannedFingerprint() {
            await this.api.post(
                `/user/${this.user.model.id}/allowBannedFingerprint`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async remainVetted() {
            await this.api.post(`/user/${this.user.model.id}/remainVetted`);
            this.$store.dispatch("userEdit/refresh");
        },
        async allowTrackedKeywords() {
            await this.api.post(
                `/user/${this.user.model.id}/allowTrackedKeywords`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async allowNearbyHotspot() {
            await this.api.post(
                `/user/${this.user.model.id}/allowNearbyHotspot`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async allowInvalidLocale() {
            await this.api.post(
                `/user/${this.user.model.id}/allowInvalidLocale`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async whitelist() {
            await this.api.post(`/user/${this.user.model.id}/whitelist`);
            this.$store.dispatch("userEdit/refresh");
        },
        async carteblanche() {
            await this.api.post(`/user/${this.user.model.id}/carteblanche`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>