<template>
    <div>
        <b-btn
            @click="selectTab(null)"
            :variant="!$route.params?.tab ? 'primary' : 'light'"
            class="mr-2 mt-2"
            size="sm"
        >
            <font-awesome-icon icon="fa-duotone fa-dashboard" />
            <small class=""> Dashboard </small>
        </b-btn>
        <b-btn
            :key="tab.key"
            v-for="tab in tabs"
            @click="selectTab(tab)"
            :variant="tab.key === $route.params?.tab ? 'primary' : 'light'"
            class="mr-2 mt-2"
            size="sm"
        >
            <font-awesome-icon class="icon" :icon="tab.icon" />
            <small class="">
                {{ tab.name }}
            </small>
            <b-badge
                v-if="tab.count"
                pill
                :class="tab.class"
                variant="dark"
                class="ml-1"
            >
                {{ tab.count }}
            </b-badge>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["tabs", "user"]),
    },
    methods: {
        selectTab(tab) {
            this.$store.dispatch("userEdit/changeTab", {
                tab: tab?.key,
                route: this.$route,
            });
        },
    },
    components: {},
};
</script>