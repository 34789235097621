import TrackerView from '@/components/tracker/TrackerView'

import UserPhotoUpdateIndex from '@/components/userPhotoUpdate/UserPhotoUpdateIndex'

import KeywordReview from '@/components/keyword/KeywordReview'
import KeywordIndex from '@/components/keyword/KeywordIndex'
import KeywordEdit from '@/components/keyword/KeywordEdit'
import KeywordCreate from '@/components/keyword/KeywordCreate'
import KeywordInstance from '@/components/keyword/KeywordInstance'

import UserReportIndex from '@/components/userReport/UserReportIndex'

import AuthorizeTransactionView from '@/components/authorizeTransaction/AuthorizeTransactionView'
import AuthorizeTransactionIndex from '@/components/authorizeTransaction/AuthorizeTransactionIndex'

import VideoIndex from '@/components/video/VideoIndex'

import WithdrawIndex from '@/components/withdraw/WithdrawIndex'
import WithdrawView from '@/components/withdraw/WithdrawView'

import BlacklistIndex from '@/components/blacklist/BlacklistIndex'
import BlacklistView from '@/components/blacklist/BlacklistView'
import BlacklistCreate from '@/components/blacklist/BlacklistCreate'

import FlagIndex from '@/components/flag/FlagIndex'
import VerificationIndex from '@/components/verification/VerificationIndex'

import DashboardIndex from '@/components/dashboard/DashboardIndex'

import ScreenIndex from '@/components/screen/ScreenIndex'
import ScreenQueue from '@/components/screen/ScreenQueue'

import ImmediateIndex from '@/components/immediate/ImmediateIndex'

import ReviewIndex from '@/components/review/ReviewIndex'
import ReviewQueue from '@/components/review/ReviewQueue'

import DocumentIndex from '@/components/document/DocumentIndex'
import IdIndex from '@/components/id/IdIndex'

import BuyerRiskIndex from '@/components/buyerRisk/BuyerRiskIndex'

import VettingIndex from '@/components/vetting/VettingIndex'
import VettingQueue from '@/components/vetting/VettingQueue'

import BuyerIndex from '@/components/buyer/BuyerIndex'

import PaidShareIndex from '@/components/paidShare/PaidShareIndex'
import PhotoIndex from '@/components/photo/PhotoIndex'

import SearchIndex from '@/components/search/SearchIndex'
import HistoryIndex from '@/components/history/HistoryIndex'

import UserIndex from '@/components/user/UserIndex'
import UserEdit from '@/components/user/UserEdit'

import FallbackPage from '@/page/FallbackPage'

const routes = [
    {
        path: '/',
        component: DashboardIndex,
    },
    {
        path: '/userReport',
        component: UserReportIndex,
    },
    {
        path: '/userPhotoUpdate',
        component: UserPhotoUpdateIndex,
        name: 'userPhotoUpdate',
    },
    {
        path: '/video/index',
        component: VideoIndex,
        name: 'videoIndex',
    },
    {
        path: '/withdraw/index',
        component: WithdrawIndex,
        name: 'withdrawIndex',
    },
    {
        path: '/withdraw/view/:id',
        component: WithdrawView,
    },
    {
        path: '/tracker/:id',
        component: TrackerView,
    },
    {
        path: '/blacklist/index',
        component: BlacklistIndex,
        name: 'blacklistIndex',
    },
    {
        path: '/blacklist/view/:id',
        component: BlacklistView,
    },
    {
        path: '/blacklist/create',
        component: BlacklistCreate,
        name: 'blacklistCreate',
    },
    {
        path: '/authorizeTransaction/index',
        component: AuthorizeTransactionIndex,
    },
    {
        path: '/authorizeTransaction/view/:id',
        component: AuthorizeTransactionView,
    },
    {
        path: '/screen',
        component: ScreenIndex,
    },
    {
        path: '/screen/queue',
        component: ScreenQueue,
    },
    {
        path: '/immediate',
        component: ImmediateIndex,
    },
    {
        path: '/review',
        component: ReviewIndex,
    },
    {
        path: '/review/queue',
        component: ReviewQueue,
    },
    {
        path: '/buyerRisk',
        component: BuyerRiskIndex,
    },
    {
        path: '/document',
        component: DocumentIndex,
    },
    {
        path: '/id',
        component: IdIndex,
    },
    {
        path: '/vetting',
        component: VettingIndex,
    },
    {
        path: '/vetting/queue',
        component: VettingQueue,
    },
    {
        path: '/buyers',
        component: BuyerIndex,
    },
    {
        path: '/paidShare',
        component: PaidShareIndex,
    },
    {
        path: '/photos',
        component: PhotoIndex,
    },
    {
        path: '/keyword/index',
        component: KeywordIndex,
    },
    {
        path: '/keyword/create',
        component: KeywordCreate,
    },
    {
        path: '/keyword/review',
        component: KeywordReview,
    },
    {
        path: '/keyword/edit/:id',
        component: KeywordEdit,
    },
    {
        path: '/keyword/instance/:id',
        component: KeywordInstance,
    },
    {
        path: '/verification/index',
        component: VerificationIndex,
    },
    {
        path: '/flag/index',
        component: FlagIndex,
    },
    {
        path: '/user/index',
        component: UserIndex,
        name: 'userIndex',
    },
    {
        path: '/user/edit/:id/:tab?',
        component: UserEdit,
        name: 'userEdit',
    },
    {
        path: '/search',
        component: SearchIndex,
    },
    {
        path: '/history',
        component: HistoryIndex,
    },
    {
        path: '*',
        component: FallbackPage,
        name: 'fallback',
    },
];

export default routes;
