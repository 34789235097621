<template>
    <div v-if="isRoot && user.canWithdraw" class="p-2 border">
        <div class="text-center">Withdraw</div>

        <b-btn-group class="w-100">
            <b-btn @click="automaticModal = true" size="sm" variant="light">
                <h5 class="mb-0">
                    {{ user.automaticWithdrawLimitCredits }}
                </h5>
                Automatic
            </b-btn>
            <b-btn @click="minimumModal = true" size="sm" variant="light">
                <h5 class="mb-0">
                    {{ fields.minimumWithdrawCredits }}
                </h5>
                Minimum
            </b-btn>
        </b-btn-group>

        <b-modal
            centered
            v-model="automaticModal"
            title="Automatic Withdrawal Limit"
            hide-footer
        >
            <b-alert variant="primary" show>
                Users will be able to withdraw credits without manual approval
                up to this amount
            </b-alert>

            <b-form-input
                size="lg"
                v-model="automaticWithdrawLimit"
            ></b-form-input>

            <b-btn
                @click="saveAutomatic"
                size="lg"
                class="mt-2"
                block
                variant="primary"
            >
                Save
            </b-btn>
        </b-modal>

        <b-modal
            centered
            v-model="minimumModal"
            title="Automatic Withdrawal Limit"
            hide-footer
        >
            <b-alert variant="primary" show>
                Users will be required to have this many credits to submit a
                withdrawal
            </b-alert>

            <b-form-input size="lg" v-model="withdrawalMinimum"></b-form-input>

            <b-btn
                @click="saveMinimum"
                size="lg"
                class="mt-2"
                block
                variant="primary"
            >
                Save
            </b-btn>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    mounted() {
        this.automaticWithdrawLimit = this.user.automaticWithdrawLimitCredits;
    },
    data() {
        return {
            withdrawalMinimum: null,
            automaticWithdrawLimit: null,
            minimumModal: false,
            automaticModal: false,
        };
    },
    computed: {
        ...mapState("userEdit", ["user", "fields"]),
        ...mapState("user", ["isRoot"]),
    },
    methods: {
        async saveAutomatic() {
            await this.api.post(
                `/user/${this.user.model.id}/automaticWithdrawLimit`,
                {
                    automaticWithdrawLimit: this.automaticWithdrawLimit,
                }
            );

            this.automaticModal = false;
            this.$store.dispatch("userEdit/refresh");
        },
        async saveMinimum() {
            await this.api.post(
                `/user/${this.user.model.id}/withdrawalMinimum`,
                {
                    withdrawalMinimum: this.withdrawalMinimum,
                }
            );

            this.minimumModal = false;
            this.$store.dispatch("userEdit/refresh");
        },
    },
    components: {},
};
</script>