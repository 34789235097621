<template>
    <div>
        <h3 class="mb-0">Root Actions</h3>

        <div class="p-3 bg-light border rounded">
            <b-dropdown text="Brand" size="sm" class="mr-2">
                <b-dropdown-item
                    :key="brand.id"
                    v-for="brand in brands"
                    @click="setBrand(brand)"
                >
                    <img
                        class="rounded"
                        height="24"
                        width="24"
                        :src="brand.logo100"
                    />
                    {{ brand.name }}
                </b-dropdown-item>
            </b-dropdown>

            <b-btn size="sm" @click="impersonateModal = true" class="mr-2">
                Impersonate
            </b-btn>

            <b-btn size="sm" @click="creditsModal = true" class="mr-2">
                Add/Remove Credits
            </b-btn>

            <b-btn size="sm" @click="shameModal = true" class="mr-2">
                Shame
            </b-btn>

            <b-btn
                @click="enableIdlePay"
                v-if="user.flags.possibleIdler"
                variant="info"
                size="sm"
            >
                Enable Idle Pay (will notify)
            </b-btn>
        </div>

        <div class="d-flex align-items-center justify-content-between mt-3">
            <h3 class="mb-0">Raw Model Values</h3>
            <div>
                <b-form-input
                    v-model="searchValues"
                    placeholder="Search fields..."
                />
            </div>
        </div>

        <b-table-simple bordered class="mt-3">
            <b-tbody>
                <b-tr v-for="(value, key) in filteredValues" :key="key">
                    <b-th class="text-right bg-light w-25">{{ key }}</b-th>
                    <b-td>{{ value }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-modal
            centered
            v-model="impersonateModal"
            title="Impersonate"
            hide-footer
        >
            <user-impersonate />
        </b-modal>

        <b-modal centered v-model="shameModal" title="Shame" hide-footer>
            <user-shame />
        </b-modal>

        <b-modal
            centered
            v-model="creditsModal"
            title="Add/Remove Credits"
            hide-footer
        >
            <h5>Current Balance</h5>
            <coin-badge :amount="user.balance" />

            <user-credits class="mt-3" />
        </b-modal>
    </div>
</template>


<script>
import { mapState } from "vuex";

import UserImpersonate from "@/components/user/root/UserImpersonate";
import UserCredits from "@/components/user/root/UserCredits";
import UserShame from "@/components/user/root/UserShame";

export default {
    data() {
        return {
            impersonateModal: false,
            creditsModal: false,
            brands: null,
            searchValues: null,
            shameModal: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
        filteredValues() {
            if (this.searchValues) {
                const lowerSearchTerm = this.searchValues;

                const result = {};

                Object.entries(this.user.model).forEach(([key, value]) => {
                    if (
                        key.toLowerCase().includes(lowerSearchTerm) ||
                        (typeof value === "string" &&
                            value.toLowerCase().includes(lowerSearchTerm))
                    ) {
                        result[key] = value;
                    }
                });

                return result;
            }

            return this.user.model;
        },
    },
    methods: {
        refresh() {
            this.api.post(`/brand/index`).then((response) => {
                this.brands = response.data.brands;
            });
        },
        async setBrand(brand) {
            await this.api.post(
                `/user/${this.user.model.id}/brand/${brand.id}`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async enableIdlePay() {
            await this.api.post(`/user/${this.user.model.id}/enableIdlePay`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
    components: {
        UserImpersonate,
        UserCredits,
        UserShame,
    },
};
</script>